import React from "react";
import { useState,useEffect } from "react";
import '../index.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Switch,FormControlLabel,Select,MenuItem,ListItemText,useMediaQuery,Typography,Collapse,IconButton,Box,CircularProgress,Link } from "@mui/material";
import AppsIcon from '@mui/icons-material/Apps';
import { useTheme } from '@mui/material/styles';
import ChartScatterplot from '../components/ChartScatterplot.jsx';
import CategoryFilter from '../components/FilterCategory.jsx'
import ButtonExport from "../components/ButtonExport.jsx";
import LabelIcon from '@mui/icons-material/Label';
import { useAuth0 } from '@auth0/auth0-react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function Dashboard({categoryInput,open, handleToggleCollapse}){
  const theme = useTheme();
  const [displayChart,setDisplayChart]=useState(false)
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  const [selectedCategory, setSelectedCategory] = useState(isMobile?[
    20]: [20]); // Change to array for multiple selections
  const [metric,setMetric] = React.useState(1)
  const [yMetric,setYMetric] = React.useState(1)
  const [sliderIcon, setSliderIcon] = useState(true);
  const [sliderLabel, setSliderLabel] = useState(true);
  const { logout,isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [outputData, setOutputData] = useState([]); // Initialize as an empty array

  const [kbmData, setKbmData] = useState([]); // State for API data
  const apiUrl = 'https://mwl26uv0m7.execute-api.us-east-1.amazonaws.com/prod/validate'; // Use the API Gateway URL from CDK output
  const fileKey = 'valuation.json'
  // Fetch data function
  useEffect(() => {
    const fetchAuthData = async () => {
      if (!isAuthenticated) return; // Wait until the user is authenticated

      setLoading(true);
      try {
        // Retrieve the token from Auth0
        const token = await getAccessTokenSilently({
          audience: "https://api.softwareiq.io", // Replace with your Auth0 audience
          scope: "read:news", // Replace with your API's required scopes
        });
    
        // Make the API call with the token and fileKey
        const response = await axios.get(`${apiUrl}?fileKey=${fileKey}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        setKbmData(response.data.data.data); // Save fetched data to state
      } catch (error) {
        console.error("Error fetching data", error);
    
        if (
          error.message.includes("Missing Refresh Token") ||
          error.error === "login_required"
        ) {
          // Force the user to sign out
          logout({ returnTo: window.location.href });
        } else {
          setError("Error fetching data");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAuthData();
  }, [isAuthenticated, getAccessTokenSilently]);
  useEffect(() => {
    if (Array.isArray(categoryInput)) {
      setSelectedCategory(categoryInput);
    } else {
      setSelectedCategory(isMobile?[10]:[1,10,11,12]);
    }
  }, [categoryInput]);
  const metricList =[
    {"id":1,"format":"percent","dbField":"revenue","field":"Revenue"},
    {"id":2,"format":"percent","dbField":"grossMargin","field":"Gross Margin"},
    {"id":3,"format":"percent","dbField":"currentCalculatedBillings","field":"Calculated Billings"},
    {"id":4,"format":"percent","dbField":"currentRpo","field":"Current RPO"},
    {"id":5,"format":"percent","dbField":"rpo","field":"RPO"},
    {"id":6,"format":"percent","dbField":"arr","field":"ARR"},
    {"id":7,"format":"percent","dbField":"ruleOf40","field":"Rule of 40"},
    {"id":8,"format":"percent","dbField":"ruleOfX","field":"Rule of X"},
    {"id":9,"format":"percent","dbField":"ruleOfXBookings","field":"Rule of X Bookings"}
  ]
  const valuationList =[
    {"id":1,"format":"percent","dbField":"evToSalesNTM","field":"EV / Sales NTM"},
    {"id":2,"format":"percent","dbField":"evToGrossMarginNTM","field":"EV / GM NTM"},
    {"id":3,"format":"percent","dbField":"evToSalesTTM","field":"EV / Sales TTM"},
    {"id":4,"format":"percent","dbField":"evToGrossMarginTTM","field":"EV / GM TTM"},
  ]
  const filterCategories = [
    { id: 10, label: 'CRM, Marketing & Support' },
    { id: 11, label: 'HR, Finance, Legal & SCM' },
    { id: 12, label: 'Productivity & Content Creation'},
    { id: 20, label: 'Database & Data Platforms' },
    { id: 21, label: 'DevTools & IT Ops'},
    { id: 22, label: 'AI Tools, IaaS, APIs & Low Code'},
    { id: 23, label: 'Security, Compliance & UEM'},
    { id: 24, label: 'Networking & CDN'},
    { id: 30, label: 'Financials'},
    { id: 31, label: 'Engineering & Industrials' },
    { id: 32, label: 'Health Care'},
    { id: 33, label: 'Consumer'},
    { id: 34, label: 'Public Sector'},
    { id: 35, label: 'Other Vertical'},
    { id: 4, label: 'Diversified'},
];
  const handleIconSliderChange = (event, newValue) => {
    setSliderIcon(newValue);

  };
  const handleLabelSliderChange = (event, newValue) => {
    setSliderLabel(newValue);

  };
  const handleMetricChange = (event) => {
    
    setMetric(event.target.value);
};  
const handleYMetricChange = (event) => {
  setYMetric(event.target.value);
};  
  
const handleCategoryChange = (event) => {
    
  const { value: selectedItems } = event.target;

  setSelectedCategory(prevCheckedCategory => {
      // Check if '1' has been newly checked
      const oneChecked = selectedItems.includes(1) && !prevCheckedCategory.includes(1);
      const oneUnchecked = !selectedItems.includes(1) && prevCheckedCategory.includes(1);
      const twoChecked = selectedItems.includes(2) && !prevCheckedCategory.includes(2);
      const twoUnchecked = !selectedItems.includes(2) && prevCheckedCategory.includes(2);
      const threeChecked = selectedItems.includes(3) && !prevCheckedCategory.includes(3);
      const threeUnchecked = !selectedItems.includes(3) && prevCheckedCategory.includes(3);

      // Initialize the new state with the selected items
      let newState = [...selectedItems];

      if (oneChecked) {
          newState.push(10,11,12); 
      }
      if (oneUnchecked) {
        newState = newState.filter(item => ![10, 11, 12].includes(item));
      }
      if (twoChecked) {
        newState.push(20,21,22,23,24); 
      }
      if (twoUnchecked) {
        newState = newState.filter(item => ![20,21,22,23,24].includes(item));
      }
      if (threeChecked) {
        newState.push(30,31,32,33,34,35); 
      }
      if (threeUnchecked) {
        newState = newState.filter(item => ![30,31,32,33,34,35].includes(item));
      }
      if (!oneChecked && !oneUnchecked && newState.includes(10) && newState.includes(11) && newState.includes(12)&&!newState.includes(1)) {
        newState.push(1);
      }
      if (!oneChecked && !oneUnchecked && !newState.includes(10) && !newState.includes(11) && !newState.includes(12)&&newState.includes(1)) {
        newState = newState.filter(item => ![1].includes(item));
      }
      if (!twoChecked && !twoUnchecked && newState.includes(20) && newState.includes(21) && newState.includes(22) && newState.includes(23) && newState.includes(24) &&!newState.includes(2)) {
        newState.push(2);
      }
      if (!twoChecked && !twoUnchecked && !newState.includes(20) && !newState.includes(21) && !newState.includes(22)&& !newState.includes(23)&& !newState.includes(24)&&newState.includes(2)) {
        newState = newState.filter(item => ![2].includes(item));
      }
      if (!threeChecked && !threeUnchecked && newState.includes(30) && newState.includes(31) && newState.includes(32)&& newState.includes(33) && newState.includes(34)&&newState.includes(35)&&!newState.includes(3)) {
        newState.push(3);
      }
      if (!threeChecked && !threeUnchecked && !newState.includes(30) && !newState.includes(31) && !newState.includes(32)&& newState.includes(33) && newState.includes(34)&&newState.includes(35)&&newState.includes(3)) {
        newState = newState.filter(item => ![3].includes(item));
      }
      return newState;
  });
}


    useEffect(() => {
        window.scrollTo(0, 0);
    

      }, []); 
    

      
      
// Handle the scenario where there are no selected categories

        const shouldWrap = useMediaQuery('(max-width: 1200px)');

          const chartMaxWidth = '2000px';
          const getFormatForMetric = (metricId) => {
            const metricItem = metricList.find(item => item.id === metricId);
            return metricItem ? metricItem.format : null;
        };
        const format = getFormatForMetric(metric);

        const currentMetric = metricList.find(item => item.id === metric)?.field
        const currentValuation = valuationList.find(item => item.id === yMetric)?.field
        const tableWidth='2000px'
    //data = data.sort((a, b) => b["Net Revenue Retention"] - a["Net Revenue Retention"]);  
    
    useEffect(() => {
      if (!Array.isArray(kbmData) || selectedCategory.length === 0) {
        setOutputData([
          {
            id: "group A",
            data: [], // Empty data structure if no valid data
          },
        ]);
        return;
      }
    
      const data = kbmData
        .filter((company) =>
          selectedCategory.some((item) =>
            company.category === filterCategories.find((i) => i.id === item)?.label
          )
        )
        .map((company) => {
          const xValue = company.valuesX[0][
            metricList.find((item) => item.id === metric)?.dbField
          ];
          const yValue = company.valuesY[0][
            valuationList.find((item) => item.id === yMetric)?.dbField
          ];
          if (typeof xValue !== "undefined" && !isNaN(xValue) && typeof yValue !== "undefined") {
            return {
              ticker: company.ticker,
              x: xValue,
              y: yValue,
            };
          }
          return null; // Explicitly return null for invalid data
        })
        .filter((item) => item !== null); // Remove null values
    
      setOutputData([
        {
          id: "group A",
          data: data,
        },
      ]);
      setDisplayChart(Array.isArray(selectedCategory) && selectedCategory.length > 0 && 
    data.length > 0)
    }, [kbmData, selectedCategory,currentMetric,currentValuation]);  // Dependencies for the useEffect

  return(

    <Box sx={{ height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }} >
    <Box
      sx={{
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
                <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', width:'1050px',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              maxWidth: '2000px',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile?'200px':'65px',
                fontSize: '11px',
                textAlign: 'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Dashboard Filters
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <CategoryFilter 
                  selectedCategory={selectedCategory} 
                  handleCategoryChange={handleCategoryChange} 
                />
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <Box sx={{ height: '30px', width: 'auto', display: 'flex', flexDirection: 'row', minWidth: '150px', maxWidth: '100%', flex: 1,}}>
              <Select
                      size="small"
                      value={metric}
                      onChange={handleMetricChange}
                      sx={{ backgroundColor: theme.tableHeader.highlight,width: '100%' }}
                      renderValue={() => (
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '12px',
                            display: 'flex',
                            alignContent: 'center',
                            paddingTop: '3px',
                            color: theme.palette.text.secondary,
                          }}
                        >
                          Select X-Axis Field
                        </Typography>
                      )}
                  >
                      {metricList.map((item) => (
                          <MenuItem size="small" key={item.id} value={item.id}>    
                            <ListItemText
                            primary={<Typography sx={{ color: theme.palette.text.secondary }} variant="body2">{item.field}</Typography>}
                            />
                        </MenuItem>
                      ))}
                  </Select>
                  </Box>
                </Box>
                <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <Box sx={{ height: '30px', width: 'auto', display: 'flex', flexDirection: 'row', minWidth: '150px', maxWidth: '100%', flex: 1,}}>
              <Select
                      size="small"
                      value={yMetric}
                      onChange={handleYMetricChange}
                      sx={{ backgroundColor: theme.tableHeader.highlight,width: '100%' }}
                      renderValue={() => (
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '12px',
                            display: 'flex',
                            alignContent: 'center',
                            paddingTop: '3px',
                            color: theme.palette.text.secondary,
                          }}
                        >
                          Select Y-Axis Field
                        </Typography>
                      )}
                  >
                      {valuationList.map((item) => (
                          <MenuItem size="small" key={item.id} value={item.id}>    
                            <ListItemText
                            primary={<Typography sx={{ color: theme.palette.text.secondary }} variant="body2">{item.field}</Typography>}
                            />
                        </MenuItem>
                      ))}
                  </Select>
                  </Box>
                </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <Box sx={{ height: '30px', width: 'auto', display: 'flex', flexDirection: 'row', minWidth: '125px', maxWidth: '100%', flex: 1,}}>
              <Box sx={{ padding:'2px',backgroundColor:theme.tableHeader.highlight,width:'125px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                border: theme.palette.mode==='light'?`1px solid ${theme.palette.grey[400]}`:`1px solid ${theme.palette.grey[700]}`, borderRadius: '4px' }}>
               <AppsIcon sx={{color: theme.palette.text.secondary,fontSize:'20px'}}/> 
                <FormControlLabel
                  control={<Switch checked={sliderIcon} onChange={handleIconSliderChange} />}
                  label={
                    <Typography variant="body2" sx={{ borderLeft: `1px solid ${theme.palette.divider}`,paddingLeft:'6px',color:theme.palette.text.secondary,fontSize: '9px' }}> {/* Adjust variant as needed */}
                      {sliderIcon ? 'Displaying Icons' : 'Displaying Nodes'}
                    </Typography>
                  }
                />
              </Box>
                  </Box>
                </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <Box sx={{ height: '30px', width: 'auto', display: 'flex', flexDirection: 'row', minWidth: '125px', maxWidth: '100%', flex: 1,}}>
              <Box sx={{ padding:'2px',backgroundColor:theme.tableHeader.highlight,width:'125px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                border: theme.palette.mode==='light'?`1px solid ${theme.palette.grey[400]}`:`1px solid ${theme.palette.grey[700]}`, borderRadius: '4px' }}>
               <LabelIcon sx={{color: theme.palette.text.secondary,fontSize:'20px'}}/> 
                <FormControlLabel
                  control={<Switch checked={sliderLabel} onChange={handleLabelSliderChange} />}
                  label={
                    <Typography variant="body2" sx={{ borderLeft: `1px solid ${theme.palette.divider}`,paddingLeft:'6px',color:theme.palette.text.secondary,fontSize: '9px' }}> {/* Adjust variant as needed */}
                      {sliderLabel ? 'Displaying Labels' : 'No Labels'}
                    </Typography>
                  }
                />
              </Box>
                  </Box>
                </Box>

                
        </Box>
        </Box>


         </Box>
         <Box sx={{ marginTop: '6px' }}></Box>


          <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        </Box>
        </Collapse>
        

    </Box>
      {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'  }}>
              <CircularProgress /> {/* This is the MUI spinner */}
            </Box>):
                 (  
  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box sx={{ 
      flex: 1, display: 'flex', 
      justifyContent:'space-between',
      paddingBottom: '4px', 
      width: tableWidth, 
      maxWidth: "98%", 
      color: theme.palette.primary.main, 
      fontSize: '20px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '0.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >
      <Box>
          <Typography variant="h6" sx={{ flexGrow: 1,paddingBottom:'0px' }}>    {'Valuation Plot: '} 
          {!isTablet && `${currentMetric} Growth vs. ${currentValuation}`}</Typography>

          {/* Link to the documentation page */}
          <Link 
          component={RouterLink}
            to="/documentation/metrics"  // Update this URL to the correct documentation page
            underline="hover"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.primary.main,
              cursor: 'pointer',
              fontSize: '16px',
              marginTop:'-5px',
              fontWeight: 400,
              marginLeft: 1,
            }}
          >
            <Typography 
              variant="body2" 
              sx={{
                fontWeight: 'light',
                fontStyle: 'italic',
                fontSize: '10px',
                color: theme.palette.text.secondary,
              }}
            >
              Our Metric Methodology
            </Typography>
            <ArrowForwardIcon sx={{ color: theme.palette.text.secondary,marginLeft: '2px', fontSize: '10px', verticalAlign: 'middle' }} />
          </Link>
          </Box>
    {false&&<ButtonExport
    
    />}
  </Box>
  <Box sx={{ display: 'flex', height: '100%', maxWidth:'95%',width: tableWidth }}>
  <div
    style={{
      position: 'relative',
      flex: 1, // This allows it to grow and fill the container
    }}
  >
    <div style={{
      position: 'absolute', width: '100%', height: '98%',
    }}>
        {displayChart&&<ChartScatterplot data={outputData} theme={theme} xLabel={currentMetric} yLabel={currentValuation} hasLabel={sliderLabel} hasIcon={sliderIcon}/>}
        <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 55, // Adjust the distance from the bottom
                left: 90, // Adjust the distance from the right
                width: '130px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.2, // Set the desired opacity for the watermark
              }}
            />
            </div>
            </div>
            </Box>
    </Box>)}
    
    </Box>

    )
}
export default Dashboard