import React, { useState, lazy, Suspense,useEffect } from "react";
import ReactGA from 'react-ga4';
import { useNavigate,useLocation } from 'react-router-dom';
import { Collapse,IconButton,Box, Tabs,Tab,Typography,MenuItem,Menu } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0
import LoginNotice from "../components/LoginNotice";
import LockIcon from '@mui/icons-material/Lock';


const CoverageMarket = lazy(() => import("../subpages/CoverageMarket"));

function News() {
  const { isAuthenticated } = useAuth0(); // Use the useAuth0 hook
  //const isAuthenticated = true
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  const isSmall  = useMediaQuery('(max-width:1300px)'); // Adjust breakpoint as needed
  const [open, setOpen] = useState(!isMobile); // State to manage the open/closed state
  const location = useLocation(); // Get current URL location
  const [anchorEl, setAnchorEl] = useState(null);
  const openBread = Boolean(anchorEl);
  const [selectedButton, setSelectedButton] = useState(0);
  const [menuLabel, setMenuLabel] = useState("News");
  const [heightUnit, setHeightUnit] = useState('vh');

  useEffect(() => {
    const supportsSvh = CSS.supports('height', '1svh');
    if (supportsSvh) {
      setHeightUnit('svh');
    }
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);
  // Function to map URL to tab index and menu label
  const getTabFromPath = (path) => {
    switch (path) {
      case '/news':
        return { index: 0, label: 'News' };
      case '/news/':
        return { index: 0, label: 'News' };  

      default:
        return { index: 0, label: 'News' };
    }
  };
  // Set tab based on current URL on load
  useEffect(() => {
    const { index, label } = getTabFromPath(location.pathname);
    setSelectedButton(index);
    setMenuLabel(label);
  }, [location.pathname]);

  const handleToggleCollapse = () => {
    setOpen(!open);
  };
  // Function to handle category filter change


const handleClose = () => {
  setAnchorEl(null);
};
const handleMenuItemClick = (index, label, link) => {
  setSelectedButton(index);
  setMenuLabel(label);
  handleClose();
  navigate(link);
};
  // Filter the data based on the selected category

  return (
    <Box 
      sx={{ 
        height:  `calc(100${heightUnit} - 100px)`,
        marginBottom: '0px', 
        display: 'flex', 
        flexDirection: 'column', 
        overflowY: 'auto', 
        flexGrow: 1 // Ensure this container can grow to fill available space
      }}
    >

  <Box
    sx={{
      backgroundColor: theme.background.backgroundColor,
      marginBottom: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      boxShadow: open ? '0px':theme.palette.mode === 'light'
    ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
    : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
    
    }}
    elevation={0} // No default elevation, custom shadow
  >
  {(isMobile||!open) &&
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>



      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',  // Make sure the entire box is clickable
          marginRight: '5%', 
        }}
      >
        <Typography
          variant="body2"
          sx={{ 
            color: theme.palette.primary.main, 
            fontWeight: 'normal', 
            
          }}
        >
          Industry News
        </Typography>

      </Box>
      
      <Menu
      anchorEl={anchorEl}
      open={openBread}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleMenuItemClick(0, 'News', '/news')}>Top News</MenuItem>

    </Menu>
    
    {!open&& <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              borderRadius:'5%',
              padding: "0px 2px",
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            <Typography variant="body2" sx={{color: theme.palette.text.secondary,fontStyle:'italic'}}>{(isMobile )?'Filters':'Expand Menu'}</Typography>
            {<ExpandMoreIcon />}
          </IconButton>}
          </Box>}
  {!isMobile&&<Collapse in={open} sx={{ width: '100%' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '6px', paddingBottom: '0px', marginLeft: '0%', color: theme.palette.primary.main, fontSize: '30px', fontWeight: '600' }}>
      Coverage Universe
    </Box>
    <Box sx={{paddingTop:'4px',paddingLeft:'1%',paddingRight:'1%',maxWidth:'100%',display:'flex', justifyContent:'center'}}>
      <Box sx={{width:'900px',display:'flex', justifyContent:'center',borderBottom: `1px solid ${theme.palette.divider}`}}>

      </Box>
      </Box>
      </Collapse>}
      
    </Box>
    <Suspense >
        {selectedButton === 0 && <CoverageMarket open={open} handleToggleCollapse={handleToggleCollapse} />}
      </Suspense>
</Box>
  );
}

export default News;