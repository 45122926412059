import React, { useState,useMemo } from 'react';
import {Link} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {IconButton, Drawer, AppBar, TextField, Box, Button, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import {FaUserSecret} from 'react-icons/fa';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import '../index.css';
import itemListData from '../data/companylistsearch.json';
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';  
import Brightness4Icon from '@mui/icons-material/Brightness4';
import LockIcon from '@mui/icons-material/Lock';
import TableChartIcon from '@mui/icons-material/TableChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CloudIcon from '@mui/icons-material/Cloud';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PublicIcon from '@mui/icons-material/Public';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ShowChartIcon from '@mui/icons-material/ShowChart';

function Header({ themeMode,toggleTheme }) {
    const [searchOpen, setSearchOpen] = useState(false);  // Add searchOpen state
    const [anchorElPublic, setAnchorElPublic] = React.useState(null);
    const [anchorElPrivate, setAnchorElPrivate] = React.useState(null);

    const handlePublicOpen = (event) => setAnchorElPublic(event.currentTarget);
    const handlePrivateOpen = (event) => setAnchorElPrivate(event.currentTarget);
    const handlePublicClose = () => setAnchorElPublic(null);
    const handlePrivateClose = () => setAnchorElPrivate(null);
    const itemList = useMemo(() => {    
      // Perform any additional filtering or sorting here if needed
      return itemListData;
    }, []);
    const theme = useTheme()

    const { loginWithRedirect, logout, isAuthenticated,user,isLoading } = useAuth0();
  const LoginButton = () => {
    const handleLoginClick = () => {
        const returnTo = window.location.pathname;
        localStorage.setItem('redirectTo', returnTo);  // Save it to localStorage
    
        loginWithRedirect();  // Trigger Auth0 login
      };
      return (
          <Button
          style={{ textTransform: 'none' }}
              tabIndex={-1}
              
              startIcon={<AccountCircleIcon />}
              onClick={handleLoginClick}
          >
              Sign In
          </Button>
      );
  };

  const LogoutButton = () => {
      return (
          <Button
          style={{ textTransform: 'none' }}
              tabIndex={-1}
              startIcon={<AccountCircleIcon />}
              onClick={() => logout({ returnTo: window.location.href })}
          >
              Sign Out
          </Button>
      );
  };

  const [anchorElEvents, setAnchorElEvents] = useState(null);
  const [anchorElInsights, setAnchorElInsights] = useState(null);
  const [anchorElBench, setAnchorElBench] = useState(null);

  const handleEventsClick = (event) => {
      setAnchorElEvents(event.currentTarget);
  };

  const handleInsightsClick = (event) => {
      setAnchorElInsights(event.currentTarget);
  };
  const handleBenchClick = (event) => {
    setAnchorElBench(event.currentTarget);
};
  const [accordionOpen, setAccordionOpen] = useState({ coverage: false, industryMetrics: false, benchmarks: false }); // Default state for accordions


  const handleClose = () => {
      setAnchorElEvents(null);
      setAnchorElInsights(null);
      setAnchorElBench(null);
      setAccordionOpen({ coverage: false, industryMetrics: false, benchmarks: false });
      setSearchOpen(false)
  };

  const [query, setQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
      const inputValue = event.target.value;
      setQuery(inputValue);

      // Check if the input has at least two characters
      if (inputValue.length >= 2) {
          // Filter items based on the query
          const filtered = itemList.filter((item) =>
              item.name.toLowerCase().includes(inputValue.toLowerCase())
          );
          setFilteredItems(filtered);
      } else {
          // If the input has less than two characters, reset the filteredItems
          setFilteredItems([]);
      }
  };
  const toggleAccordion = (panel) => (event) => {
    setAccordionOpen((prev) => ({ ...prev, [panel]: !prev[panel] }));
};
  // Function to generate links based on the selected item
  const generateLinks = (items, themeMode) => {
      return items.map((item, index) => (
          <li key={index}>
              <Box sx={{
                  transition: 'background-color 0.3s',
                  '&:hover': {
                      backgroundColor: themeMode === 'light' ? '#f0f0f0' : 'rgb(70, 70, 70)',
                  }
              }}>
                  <Link
                      to={`company${item.link.toLowerCase()}/news`}
                      onClick={() => {
                          window.scrollTo(0, 0);
                          handleClose(); // Close the dropdown
                          setQuery(''); // Reset the query
                          setFilteredItems([]);
                      }}
                      style={{ color:theme.palette.secondary.main, }}
                  >
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer', // Add cursor style to indicate it's clickable
                          }}
                      >
                          <img
                              src= {themeMode === 'light'?`/logos/${item.ticker}/icon.png`:`/logos/${item.ticker}/icondark.png`}
                              alt={`${item.ticker}`}
                              className="icon"
                              style={{ maxWidth: '30px', maxHeight: '30px', paddingRight: '10px' }}
                          />
                          {"    " + item.name}
                      </Box>
                  </Link>
              </Box>
          </li>
      ));
  };

  const shouldHide = useMediaQuery('(max-width: 950px)');
  const isDesktop = useMediaQuery('(max-width: 780px)');

  return (

            <AppBar 
            elevation={2} 
            position="sticky" 
            sx={{ 
                top: 0, 
                zIndex: 1200, 
                backgroundColor: themeMode === 'light' ? 'rgb(220,220,220)' : 'rgb(4,4,4)' 
            }}
            >
            <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', // Ensures space between left and right items
                alignItems: 'center', 
                padding: '0 2%', 
                borderBottom: themeMode === 'light' ? '1px solid #c9c9c9' : '1px solid #343434' 
            }}
            >
            {/* Left Section (Logo or Brightness Icon on small screens) */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {!isDesktop ? (
                <Link to='/'>
                    <img
                    src={themeMode === 'light'
                        ? require('../data/images/logo.png')
                        : require('../data/images/logodark.png')}
                    alt="Website Logo"
                    className="logo"
                    style={{ height: '26px', width: 'auto' }}
                    />
                </Link>
                ) : (
                <IconButton sx={{ paddingLeft:'24px',color: theme.palette.primary.main }} onClick={toggleTheme}>
                    <Brightness4Icon />
                </IconButton>
                )}
            </Box>

            {/* Right Section (Sign-in/Logout and Brightness Icon on larger screens) */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '22px' }}>
                {/* Only show the brightness icon on the right when on desktop */}
                {!isDesktop && (
                <IconButton sx={{ color: theme.palette.primary.main }} onClick={toggleTheme}>
                    <Brightness4Icon />
                </IconButton>
                )}
                
                {!isLoading && isAuthenticated && <LogoutButton />}
                {!isLoading && !isAuthenticated && <LoginButton />}
            </Box>
            </Box>

              <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: '2px',
                  paddingBottom: '0px',
                  borderBottom: themeMode === 'light' ? '1px solid #c9c9c9' : '1px solid #000000'
              }}>
                {isDesktop&&<Box sx={{ maxHeight: '28px' }} className='header-logo'>
                      <Link to='/'>
                          <img
                              src={
                                  themeMode === 'light'
                                      ? require('../data/images/logo.png')
                                      : require('../data/images/logodark.png')
                              }
                              alt="Website Logo"
                              className="logo"
                              style={{ height: '26px', width: 'auto' }}
                          />
                      </Link>
                  </Box>}

                  {!isDesktop &&
                      <Box sx ={{paddingLeft:'2.7%'}}>
                          <Button
                              style={{ textTransform: 'none' }}
                              sx={{
                                  fontSize: '15px',
                              }}
                          >
                            
                            <Link to="/coverage/all" style={{color: theme.palette.primary.main }}>
                            <Box sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                            {!shouldHide&&<PublicIcon sx={{fontSize:'20px',marginRight:'4px'}}/>}
                            Coverage Universe
                            </Box>
                              </Link>
                          </Button>                            
                      </Box>
                  }
                                    {!isDesktop &&
                      <Box sx ={{paddingLeft:'2%'}}>
                          <Button
                              style={{ textTransform: 'none' }}
                              sx={{
                                  fontSize: '15px',
                              }}
                          >
                            
                            <Link to="/news" style={{color: theme.palette.primary.main }}>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {!shouldHide&&<NewspaperIcon sx={{fontSize:'20px',marginRight:'4px'}}/>}
                                    Industry News
                                    {!isAuthenticated && <LockIcon fontSize="small" sx={{fontSize:'12px', ml: 0.5 }} />}
                                    </Box>
                              </Link>
                          </Button>                            
                      </Box>
                  }
                {!isDesktop && (
                    <Box sx={{ paddingLeft: '2%' }}>
                        <Button
                        style={{ textTransform: 'none' }}
                        aria-controls="events-menu"
                        aria-haspopup="true"
                        onClick={handleEventsClick}
                        sx={{
                            fontSize: '15px',
                            color: (theme) => theme.button.color, // Use the text color from the theme
                        }}
                        endIcon={<ArrowDropDownIcon />} // Add the arrow icon to the button
                        >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {!shouldHide&&<QueryStatsIcon sx={{fontSize:'20px',marginRight:'4px'}}/>}
                            Market Analysis
                            {!isAuthenticated && <LockIcon fontSize="small" sx={{ fontSize:'12px',ml: 0.5 }} />}
                        </Box>
                        </Button>
                        <Menu
                            id="events-menu"
                            anchorEl={anchorElEvents}
                            keepMounted
                            open={Boolean(anchorElEvents)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom', // Position the menu below the button
                                horizontal: 'center', // Center the menu horizontally
                              }}
                              transformOrigin={{
                                vertical: 'top', // Align the menu's top edge with the anchor
                                horizontal: 'center', // Center-align the menu
                              }}
                            sx={{

                                padding: '16px', // Add padding for better spacing
                            }}
                            >
                                <Box sx={{
                                display: 'flex', // Enable flexbox layout
                                flexDirection: 'row', // Arrange items in columns
                                padding: '16px', // Add padding for better spacing
                            }}>
                            {/* Public Market Section */}
                            <Box sx={{ minWidth: 200, paddingX: 2 }}>
                                <Box
                                sx={{
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                    marginTop: '8px',
                                    fontSize: '18px',
                                    
                                    color:theme.palette.primary.main,
                                }}
                                >
                                Public Markets
                                </Box>
                                <Box
                                sx={{
                                    fontWeight: 'normal',
                                    marginBottom: '12px',
                                    marginTop: '24px',
                                    marginLeft: '0px',
                                    fontSize: '16px',
                                    color:theme.palette.primary.main,
                                }}
                                >
                                Industry Metrics
                                </Box>
                                <Link
                                to="/industrymetrics/compsheet"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TableChartIcon sx={{ mr: 1}}/>
                                    Comp Sheet

                                    </Box>
                                </MenuItem>
                                </Link>
                                <Link
                                to="/industrymetrics/dashboard"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <BarChartIcon sx={{ mr: 1}}/>
                                    Metric Dashboard
                                    </Box>
                                </MenuItem>
                                </Link>

                                <Link
                                to="/industrymetrics/valuation"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ScatterPlotIcon sx={{ mr: 1}}/>
                                    Valuation Plot
                                    </Box>
                                </MenuItem>
                                </Link>
                                

                            </Box>
                            <Box sx={{ minWidth: 200, paddingX: 2 }}>
                                
                                <Box
                                sx={{
                                    fontWeight: 'normal',
                                    marginBottom: '12px',
                                    marginTop: '50px',
                                    marginLeft: '0px',
                                    fontSize: '16px',
                                    color:theme.palette.primary.main,
                                }}
                                >
                                Benchmarks
                                </Box>
                                <Link
                                to="/benchmarks/index"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ShowChartIcon sx={{ mr: 1}}/>
                                    Software Index
                                    </Box>
                                </MenuItem>
                                </Link>
                                <Link
                                to="/benchmarks/valuation"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PriceCheckIcon sx={{ mr: 1}}/>
                                    Market Valuation
                                    </Box>
                                </MenuItem>
                                </Link>
                                <Link
                                to="/benchmarks/segmentation"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <AutoAwesomeMosaicIcon sx={{ mr: 1}}/>
                                    Segmentation
                                    </Box>
                                </MenuItem>
                                </Link>

                                <Link
                                to="/benchmarks/iaas"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CloudIcon sx={{ mr: 1}}/>
                                    Public Cloud
                                    </Box>
                                </MenuItem>
                                </Link>

                            </Box>
                            {/* Private Market Section */}
                            <Box sx={{ minWidth: 200, paddingX: 2 }}>
                                
                                <Box
                                sx={{
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                    marginTop: '8px',
                                    fontSize: '18px',
                                    color:theme.palette.primary.main,
                                }}
                                >
                                Private Markets
                                </Box>
                                <Box
                                sx={{
                                    fontWeight: 'normal',
                                    marginBottom: '12px',
                                    marginTop: '20px',
                                    marginLeft: '0px',
                                    fontSize: '16px',
                                    color:theme.palette.primary.main,
                                }}
                                >
                                Coverage
                                </Box>
                                <Link
                                to="/coverage/vc"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <AddBusinessIcon sx={{ mr: 1}}/>
                                    Top Unicorns
                                    </Box>
                                </MenuItem>
                                </Link>
                                <Link
                                to="/coverage/acquisitions"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <AccountBalanceIcon sx={{ mr: 1}}/>
                                    Acquisitions
                                    </Box>
                                </MenuItem>
                                </Link>
                            </Box>
                            </Box>
                            </Menu>
                    </Box>
                    )}

                 
                  
                    {!isDesktop&&<Box></Box>}
                      {!isDesktop&&<Box></Box>}
                      {!isDesktop&&<Box></Box>}
                      {!isDesktop&&<Box></Box>}
                  {!isDesktop&&<Box sx={{ marginTop: '2px', marginBottom: '2px', display: 'flex', displayContent: 'flex-start', marginRight: "2%", marginLeft: "2%" }}>
                      {!shouldHide && (
                          <Box sx={{
                              color: (theme) => theme.palette.secondary.main, // Use the text color from the theme
                              width: '70px', fontSize: '13px', marginTop:'4px',
                              fontWeight: 'bold'
                          }}>
                              Security Analysis
                          </Box>
                      )}
                      <Box sx={{ minWidth: '100px', maxWidth: '430px', alignItems: 'right', position: 'relative', display: 'block', marginLeft: '4px' }}>
                    
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={query}
                        onChange={handleSearchInputChange}
                        sx={{ 
                            position: 'relative', 
                            
                        }}
                        InputProps={{
                            startAdornment: (
                            <SearchIcon sx={{ color: 'grey', marginRight: '4px' }} />
                            ),
                            sx: {
                                
                            '& .MuiInputBase-input': {
                                color: theme.palette.text.primary,
                                '&::placeholder': {
                                    fontSize: '13px',
                                color: theme.palette.text.secondary,
                                },
                            },
                            backgroundColor: themeMode === 'light' ? '#ffffff' : 'rgb(50, 50, 50)' },
                        }}
                        onClick={() => {
                            setQuery('');
                            setFilteredItems([]);
                        }}
                        InputLabelProps={{
                            sx: {
                            fontSize: '13px',
                            marginTop: '6px',
                            fontWeight: 'bold',
                            color: theme.palette.text.secondary,
                            backgroundColor: 'null'
                            },
                        }}
                        placeholder="Search by Company"
                        label=""
                        />
                          <Box className="dropdown-list" sx={{ backgroundColor:theme.tableHeader.highlight }}>
                              {generateLinks(filteredItems, themeMode)}
                          </Box>
                      </Box>
                  </Box>}
                  {isDesktop && (
                    <Box sx={{ paddingLeft: '0px', marginLeft: 'auto' }}>
                        <Button
                        style={{ padding: '0,0,0,0', textTransform: 'none' }}
                        aria-controls="search-menu"
                        aria-haspopup="true"
                        onClick={() => setSearchOpen(true)} // Open search drawer
                        >
                        <SearchIcon style={{ fontSize: '32px' }} />
                        </Button>
                        <Drawer
                        
                        elevation={0}
                        id="search-menu"
                        anchor="right"
                        keepMounted
                        open={searchOpen}  // Variable to control drawer visibility
                        onClose={() => setSearchOpen(false)}
                        sx={{
                            width: '100vw',
                            height: '100vh',
                            top: '83px',
                            '& .MuiDrawer-paper': {
                            backgroundColor:theme.tableHeader.highlight,
                            width: '100vw',
                            height: '100vh',
                            top: '83px',
                            },
                        }}
                        >
                        <Box sx={{ padding: '20px' }}>
                            <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={query}
                            onChange={handleSearchInputChange}
                            InputProps={{
                                startAdornment: (
                                <SearchIcon sx={{ color: 'grey', marginRight: '4px' }} />
                                ),
                                sx: {
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.primary,
                                    '&::placeholder': {
                                    fontSize: '13px',
                                    color: theme.palette.text.secondary,
                                    },
                                },
                                backgroundColor: themeMode === 'light' ? '#ffffff' : 'rgb(50, 50, 50)',
                                },
                            }}
                            placeholder="Search by Company"
                            />
                            </Box>
                            <Box className="dropdown-list" sx={{top:80, backgroundColor:theme.tableHeader.highlight }}>
                            {filteredItems.length > 0 ? (
                                generateLinks(filteredItems, themeMode)
                            ) : query.length>2&&(
                                <Box sx={{ padding: '10px', color: theme.palette.text.secondary }}>
                                No results found.
                                </Box>
                                )}
                        
                        </Box>
                        </Drawer>
                        </Box>
            )}
                  {isDesktop &&
                      <Box sx={{ paddingLeft: '5px' }}>
                          <Button
                              style={{ padding:'0,0,0,0',textTransform: 'none' }}
                              aria-controls="insights-menu"
                              aria-haspopup="true"
                              onClick={handleInsightsClick}
                          >
                            {anchorElInsights ? <CloseIcon style={{ fontSize: '28px' }} /> : <MenuIcon style={{ fontSize: '32px' }} />}
                              
                          </Button>
                          <Drawer
                            id="insights-menu"
                            anchor="right"
                            keepMounted
                            elevation={0}
                            open={Boolean(anchorElInsights)}
                            onClose={handleClose}
                            sx={{

                                width: '100vw',

                                top: '83px',
                                paddingBottom:'2px',
                                '& .MuiDrawer-paper': {
                                    backgroundColor:theme.tableHeader.highlight,
                                    width: '100vw',
                                    height: 'calc(100vh - 83px)', 
                                    top: '83px',
                                    overflowY: 'auto',
                                }
                            }}
                        >
                            <Accordion elevation={1}  sx={{color:theme.palette.primary.main,backgroundColor:theme.background.backgroundColor}}>
                            
                            <MenuItem  sx={{height:'40px',color:theme.palette.primary.main, fontWeight:'bold'}} onClick={handleClose}>
                            
                            <Link  to="/coverage/all" style={{ color:theme.palette.primary.main,textDecoration: 'none', color: 'inherit' }}>
                            <Box sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                            <PublicIcon sx={{marginRight:'6px'}}/>
                            Coverage Universe
                            </Box>
                            </Link>
                            </MenuItem>
                            </Accordion>  
                            <Accordion elevation={1}  sx={{color:theme.palette.primary.main,backgroundColor:theme.background.backgroundColor}}>
                            
                            <MenuItem  sx={{height:'40px',color:theme.palette.primary.main, fontWeight:'bold'}} onClick={handleClose}>
                            
                            <Link  to="/news" style={{ color:theme.palette.primary.main,textDecoration: 'none', color: 'inherit' }}>
                            <Box sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                            <NewspaperIcon sx={{marginRight:'6px'}}/>
                            Industry News
                            {!isAuthenticated && <LockIcon fontSize="small" sx={{ marginTop:'0px',fontSize:'12px',ml: 0.5 }} />}
                            </Box>

                            </Link>
                            </MenuItem>
                            </Accordion>   
                            
                            <Accordion sx={{color:theme.palette.primary.main,backgroundColor:theme.background.backgroundColor}} expanded={accordionOpen['industryMetrics']} onChange={toggleAccordion('industryMetrics')}>
                                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                <Box sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                                    <QueryStatsIcon sx={{marginRight:'6px'}}/>
                                    <h4>Public Markets</h4>
                                    {!isAuthenticated && <LockIcon fontSize="small" sx={{marginTop:'4px',  fontSize:'12px',ml: 0.5 }} />}
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{color:theme.palette.text.secondary}}>

                                    <Box
                                sx={{
                                    fontWeight: 'normal',
                                    marginBottom: '4px',
                                    marginTop: '0px',
                                    marginLeft: '0px',
                                    fontSize: '16px',
                                    color:theme.palette.primary.main,
                                }}
                                >
                                Industry Metrics
                                </Box>
                                    <Link
                                to="/industrymetrics/compsheet"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                    
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TableChartIcon sx={{ mr: 1}}/>
                                    Comp Sheet

                                    </Box>
                                </MenuItem>
                                </Link>
                                <Link
                                to="/industrymetrics/dashboard"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <BarChartIcon sx={{ mr: 1}}/>
                                    Metric Dashboard
                                    </Box>
                                </MenuItem>
                                </Link>

                                <Link
                                to="/industrymetrics/valuation"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ScatterPlotIcon sx={{ mr: 1}}/>
                                    Valuation Plot
                                    </Box>
                                </MenuItem>
                                </Link>
                                <Box
                                sx={{
                                    fontWeight: 'normal',
                                    marginBottom: '12px',
                                    marginTop: '4px',
                                    marginLeft: '0px',
                                    fontSize: '16px',
                                    color:theme.palette.primary.main,
                                }}
                                >
                                Benchmarks
                                </Box>
                                <Link
                                to="/benchmarks/index"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ShowChartIcon sx={{ mr: 1}}/>
                                    Software Index
                                    </Box>
                                </MenuItem>
                                </Link>
                                <Link
                                to="/benchmarks/valuation"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PriceCheckIcon sx={{ mr: 1}}/>
                                    Market Valuation
                                    </Box>
                                </MenuItem>
                                </Link>
                                <Link
                                to="/benchmarks/segmentation"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <AutoAwesomeMosaicIcon sx={{ mr: 1}}/>
                                    Segmentation
                                    </Box>
                                </MenuItem>
                                </Link>

                                <Link
                                to="/benchmarks/iaas"
                                style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                >
                                <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CloudIcon sx={{ mr: 1}}/>
                                    Public Cloud
                                    </Box>
                                </MenuItem>
                                </Link>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion sx={{color:theme.palette.primary.main,backgroundColor:theme.background.backgroundColor}} expanded={accordionOpen['coverage']} onChange={toggleAccordion('coverage')}>
                                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                <Box sx={{marginLeft:'4px',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                                    <FaUserSecret sx={{}}/>
                                    <h4 style={{marginLeft:'12px'}}>Private Markets</h4>
                                    </Box>
                                    {!isAuthenticated && <LockIcon fontSize="small" sx={{marginTop:'4px',  fontSize:'12px',ml: 0.5 }} />}
                                </AccordionSummary>
                                <AccordionDetails sx={{color:theme.palette.text.secondary}}>

                                    <Box
                                    sx={{
                                        fontWeight: 'normal',
                                        marginBottom: '4px',
                                        marginTop: '0px',
                                        marginLeft: '0px',
                                        fontSize: '16px',
                                        color:theme.palette.primary.main,
                                    }}
                                    >
                                    Coverage
                                    </Box>
                                    <Link
                                    to="/coverage/vc"
                                    style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                    >
                                    <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <AddBusinessIcon sx={{ mr: 1}}/>
                                        Top Unicorns
                                        </Box>
                                    </MenuItem>
                                    </Link>
                                    <Link
                                    to="/coverage/acquisitions"
                                    style={{ textDecoration: 'none', color: theme.palette.text.secondary }}
                                    >
                                    <MenuItem onClick={handleClose} sx={{ paddingY: '12px', fontWeight: 'light' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <AccountBalanceIcon sx={{ mr: 1}}/>
                                        Acquisitions
                                        </Box>
                                    </MenuItem>
                                    </Link>
                                </AccordionDetails>
                            </Accordion>



                        </Drawer>
                      </Box>}
              </Box>
          </AppBar>

  );
};

export default Header;