import React from "react";
import { useState,useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import '../index.css'
import Box from '@mui/material/Box';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {FaDatabase, FaIndustry, FaDesktop, FaBuilding} from 'react-icons/fa';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton,Typography,Tooltip,Collapse,ToggleButton,ToggleButtonGroup,CircularProgress } from "@mui/material";
import MyResponsiveLine from "../components/ChartLineIndex";
import FilterIndexCategory from "../components/FilterIndexCategory2";
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';


function Index({ open, handleToggleCollapse }){
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
  const items = [
    { id: "Software",idEW: "Software EW", label: 'SoftwareIQ Composite Index', icon: <FaBuilding /> },
    { id: "Application",idEW: "Application EW", label: 'SoftwareIQ Application Index', icon: <FaDesktop /> },
    { id: "Infrastructure",idEW: "Infrastructure EW", label: 'SoftwareIQ Infrastructure Index', icon: <FaDatabase /> },
    { id: "Vertical",idEW: "Vertical EW", label: 'SoftwareIQ Vertical Index', icon: <FaIndustry /> }
    // Add more items as needed
  ];
  
  const theme = useTheme();


  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts
  
  const navigate = useNavigate();
    const {user} = useSelector((state)=>state.auth);
  const tableWidth = '1600px'
  const [checked, setChecked] = useState(["Software"]);

  const [chartData, setChartData] = useState(null);
  
  const isTablet = useMediaQuery('(max-width:1080px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  
  const { logout,isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rowData,setRowData] = useState([]);
  const apiUrl = 'https://mwl26uv0m7.execute-api.us-east-1.amazonaws.com/prod/validate'; // Use the API Gateway URL from CDK output
  const fileKey = 'Index.json'
  // Fetch data function
  useEffect(() => {
    const fetchAuthData = async () => {
      if (!isAuthenticated) return; // Wait until the user is authenticated
    
      setLoading(true);
      try {
        // Retrieve the token from Auth0
        const token = await getAccessTokenSilently({
          audience: "https://api.softwareiq.io", // Replace with your Auth0 audience
          scope: "read:news", // Replace with your API's required scopes
        });
    
        // Make the API call with the token and fileKey
        const response = await axios.get(`${apiUrl}?fileKey=${fileKey}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        const data = response.data.data
        const validatedData = data.map(item => ({
          ...item,
          data: item.data.map(dataItem => ({
            ...dataItem,
            x: new Date(dataItem.x) // Ensure x is a valid date object
          })).filter(dataItem => !isNaN(dataItem.x)) // Filter out invalid dates
        }));
        setChartData(validatedData);
        
      } catch (error) {
        console.error("Error fetching data", error);
    
        if (
          error.message.includes("Missing Refresh Token") ||
          error.error === "login_required"
        ) {
          // Force the user to sign out
          logout({ returnTo: window.location.href });
        } else {
          setError("Error fetching data");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAuthData();
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleToggle = (event) => {
    const itemId = event.target.value; // Get the ID of the clicked item
    setChecked((prevChecked) => {
      if (prevChecked.indexOf(itemId) === -1) {
        // Item is not checked, add it
        return [...prevChecked, itemId];
      } else {
        // Item is already checked, remove it
        return prevChecked.filter((id) => id !== itemId);
      }
    });
  };


    const [alignment, setAlignment] = React.useState(isTablet?"oneYear":"fiveYears")
    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };

    
    
    const shouldWrap = useMediaQuery('(max-width: 790px)');


    const calculateStartDate = (lastDate, alignment) => {
      if (!lastDate || isNaN(lastDate)) {
        return new Date(); // Default to the current date if lastDate is invalid
      }
    
      const date = new Date(lastDate);
      const startOfYear = new Date(date.getFullYear(), 0, 1);
    
      switch (alignment) {
        case "oneMonth":
          date.setMonth(date.getMonth() - 1);
          break;
        case "threeMonths":
          date.setMonth(date.getMonth() - 3);
          break;
        case "oneYear":
          date.setFullYear(date.getFullYear() - 1);
          break;
        case "threeYears":
          date.setFullYear(date.getFullYear() - 3);
          break;
        case "fiveYears":
          date.setFullYear(date.getFullYear() - 5);
          break;
          case "tenYears":
            date.setFullYear(date.getFullYear() - 10);
            break;
        case "ytd":
          return startOfYear;
        default:
          date.setFullYear(date.getFullYear() - 5);
      }
    
      return date;
    };
    
    
    const getLastDate = (chartData) => {
      if (!chartData || chartData.length === 0) {
        return new Date(); // Default to the current date if chartData is null or empty
      }
    
      const allDates = chartData
        .flatMap(item => item.data.map(dataItem => new Date(dataItem.x)))
        .filter(date => !isNaN(date)); // Ensure only valid dates are considered
    
      return new Date(Math.max(...allDates));
    };
    
    const lastDate = getLastDate(chartData);
    const startDate = calculateStartDate(lastDate, alignment);
    
    const filteredData = chartData
    ? chartData
      .filter(item => {

            return checked.includes(item.id);
      })
      .map(item => ({
        id: item.id,
        data: item.data.filter(dataItem => new Date(dataItem.x) >= startDate)
      }))
    : [];

  return(
      
      <Box sx={{ height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
        <Box
      sx={{
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
                <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', width:'930px',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              maxWidth: '710px',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile?'200px':'65px',
                fontSize: '12px',
                textAlign: 'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Dashboard Filters
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '2%', marginRight: '1%', flexShrink: 1 }}>
              <FilterIndexCategory checked={checked} handleToggle={handleToggle} items={items} />
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '2%', marginRight: '1%', flexShrink: 1 }}>

                  <Box
                size="small"
                sx={{
                  
                  height: '30px',
                  width: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: '200px',
                  marginTop: '2px',
                  maxWidth: '100%',
                  flex: 1,
                }}
              >
          <Box sx={{ height:'30px',backgroundColor:theme.tableHeader.highlight,width:'270px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                border: theme.palette.mode==='light'?`1px solid ${theme.palette.grey[400]}`:`1px solid ${theme.palette.grey[700]}`, borderRadius: '4px' }}>
                  <Box sx={{ width: '45px',  textAlign: 'center',  paddingLeft: '8px',  paddingRight: '4px' }}><Typography variant="body2" sx={{ color:theme.palette.text.secondary,fontSize: '9px' }}> Time Frame</Typography></Box>
          <Box sx={{ borderLeft: `0px solid ${theme.palette.divider}`,height:'30px',display: 'flex', justifyContent: 'flex-start', flex: 1 }}>
              <Box sx={{  marginLeft: '6px', marginRight: '6px' }}>
              <ToggleButtonGroup
                sx={{
                  color: theme.palette.text.secondary,
                  backgroundColor: theme.tableHeader.highlight,
                  height: '100%',
                  paddingLeft: '0px',
                  '& .MuiToggleButton-root': {
                    borderColor: theme.palette.mode==='light'?theme.palette.grey[400]:theme.palette.grey[700], 
                    borderRadius: '0px', // Remove rounded corners
                    '&:not(:last-child)': {
                      borderRight: `1px solid ${theme.palette.divider}`, // Adjust for visible borders between buttons
                    },
                    '&.Mui-selected': {
                      borderColor: theme.toggleButton.selectedBorderColor, // Border color for selected button
                      backgroundColor: theme.toggleButton.selectedBackgroundColor, // Optional: Change background color
                      color: theme.toggleButton.selectedTextColor, // Optional: Change text color
                    },
                  },
                }}
                value={alignment}
                exclusive
                onChange={handleChange}
                size="small"
                aria-label="Time Period Toggle"
              >
                
                <ToggleButton style={{ textTransform: 'none', }} value="oneMonth" sx={{ minWidth:'10x' }}>
                  <Tooltip title="One Month">
                    <IconButton color={alignment === "oneMonth" ? 'primary' : 'default'}  sx={{ color: alignment === 'oneMonth' ? 'primary' : theme.palette.text.secondary,py:0,px:0 }}>
                      <Box>
                      <Typography align="center" sx={{ fontWeight: 'bold', fontSize: '11px'  }}>1M</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="threeMonths" sx={{ minWidth:'10x' }}>
                  <Tooltip title="Three Months">
                    <IconButton color={alignment === "threeMonths" ? 'primary' : 'default'}  sx={{ color: alignment === 'threeMonths' ? 'primary' : theme.palette.text.secondary,py:0,px:0  }}>
                      <Box>
                      <Typography align="center" sx={{ fontWeight: 'bold', fontSize: '11px'  }}>3M</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="ytd" sx={{ minWidth:'10x' }}>
                  <Tooltip title="Year to Date">
                    <IconButton color={alignment === "ytd" ? 'primary' : 'default'}  sx={{ color: alignment === 'ytd' ? 'primary' : theme.palette.text.secondary,py:0,px:0  }}>
                      <Box>
                      <Typography align="center" sx={{ fontWeight: 'bold', fontSize: '11px'  }}>YTD</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="oneYear" sx={{ minWidth:'10x' }}>
                  <Tooltip title="One Year">
                    <IconButton color={alignment === "oneYear" ? 'primary' : 'default'}  sx={{ color: alignment === 'oneYear' ? 'primary' : theme.palette.text.secondary,py:0,px:0  }}>
                      <Box>
                      <Typography align="center" sx={{ fontWeight: 'bold', fontSize: '11px'  }}>1Y</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="threeYears" sx={{ minWidth:'10x' }}>
                  <Tooltip title="Three Years">
                    <IconButton color={alignment === "threeYears" ? 'primary' : 'default'}  sx={{ color: alignment === 'threeYears' ? 'primary' : theme.palette.text.secondary,py:0,px:0  }}>
                      <Box>
                      <Typography align="center" sx={{ fontWeight: 'bold', fontSize: '11px'  }}>3Y</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="fiveYears" sx={{ minWidth:'10x' }}>
                  <Tooltip title="Five Years">
                    <IconButton color={alignment === "fiveYears" ? 'primary' : 'default'}  sx={{ color: alignment === 'fiveYears' ? 'primary' : theme.palette.text.secondary,py:0,px:0  }}>
                      <Box>
                      <Typography align="center" sx={{ fontWeight: 'bold', fontSize: '11px'  }}>5Y</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="tenYears" sx={{ minWidth:'10x' }}>
                  <Tooltip title="Ten Years">
                    <IconButton color={alignment === "tenYears" ? 'primary' : 'default'}  sx={{ color: alignment === 'tenYears' ? 'primary' : theme.palette.text.secondary,py:0,px:0  }}>
                      <Box>
                      <Typography align="center" sx={{ fontWeight: 'bold', fontSize: '11px'  }}>10Y</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
             </Box>
             </Box>
          </Box>
          </Box>
              </Box>
            </Box>
          </Box>
        </Box>


        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
    </Box>
    </Collapse>
    </Box>

    {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'  }}>
          <CircularProgress /> {/* This is the MUI spinner */}
        </Box>):
             (  
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box sx={{ 
      flex: 1, display: 'flex', 
      justifyContent:'space-between',
      paddingBottom: '6px', 
      width: tableWidth, 
      maxWidth: "98%", 
      color: theme.palette.primary.main, 
      fontSize: '20px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >
    {!isMobile&&('')}
    {'Benchmark Performance'} 

  </Box>
  <Box sx={{ display: 'flex', height: '100%', maxWidth:'95%',width: tableWidth }}>
  <div
    style={{
      position: 'relative',
      flex: 1, // This allows it to grow and fill the container
    }}
  >
    <div style={{
      position: 'absolute', width: '100%', height: '98%',
    }}>
        <MyResponsiveLine data={filteredData} theme={theme}  />
        <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 72, // Adjust the distance from the bottom
                left: 60, // Adjust the distance from the right
                width: '130px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.3, // Set the desired opacity for the watermark
              }}
            />
            </div>
            </div>
            </Box>
    </Box>)}
</Box>
    )
}
export default Index