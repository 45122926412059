import React from "react";
import { Box, Typography, List, ListItem, Divider, useTheme,useMediaQuery } from "@mui/material";
import { MathJax, MathJaxContext } from "better-react-mathjax";

const Documentation = () => {
  const theme = useTheme();
  
  const config = {
    loader: { load: ["input/asciimath", "output/chtml"] },
    tex: {
      chtml: {
        scale: 100, // Adjust the global scale factor for formulas
      },
    },
  };
  // Define breakpoints for different screen sizes

  const isExtraSmallScreen = useMediaQuery(`(max-width: 380px)`); // Extra small screen
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm')); // Small screen
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Medium screen
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg')); // Medium screen
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl')); // Large screen

  // Dynamically set formula size based on screen size
  let formulaSize;
  if (isExtraSmallScreen) {
    formulaSize = '0.5rem'; // Extra small font for extra small screens
  } else if (isSmallScreen) {
    formulaSize = '0.6rem'; // Smaller font for small screens
  } else if (isMediumScreen) {
    formulaSize = '0.8rem'; // Default font size for medium screens
  } else if (isLargeScreen) {
    formulaSize = '0.85rem'; // Larger font for large screens
  }
  else if (isExtraLargeScreen) {
    formulaSize = '1rem'; // Largest font for extra large screens
  }

  return (
    <MathJaxContext config={config}>
      <Box
        sx={{
          paddingTop:'0px',
          color: theme.palette.text.secondary,
          fontSize: "0.9rem", // Base font size for all text
          "& h4": { fontSize: "2rem" }, // Scale down headings
          "& h5": { fontSize: "1.4rem" },
          "& h6": { fontSize: ".9rem" },
          "& p": { fontSize: ".9rem" }, // Apply spacing between paragraphs
          "& > *": { marginBottom: "16px" }, // Apply spacing between elements
        }}
      >
        
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
          Metric Methodology
        </Typography>

        <Divider sx={{marginBottom:'20px',marginX:'30px'}}/>
        {/* Covered Retention Metrics */}
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main }}>
          Covered Retention Metrics
        </Typography>

        <Typography variant="body1" gutterBottom>
          Retention metrics are foundational to evaluating customer health, revenue sustainability, and growth potential in subscription-based models. These metrics are defined uniquely per company but standardized into two buckets for comparative analysis: Net Retention Rate and Net Expansion Rate. This ensures consistent evaluation regardless of individual naming conventions.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        {/* Net Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Net Retention Rate (NRR)
        </Typography>
        <Typography variant="body1" gutterBottom>
          NRR measures the year-over-year change in revenue from the same set of customers, accounting for churn, downsell, and upsell. It reflects how well a company retains and grows its existing customer base. Net revenue retention measures the change in customer spend from a year ago. This metric excludes revenue from new logos acquired in the last year. This metrics is typically calculated as follows:
        </Typography>
        <Typography  variant="body1" gutterBottom>
          <Box sx={{fontSize:formulaSize}}>
        <MathJax>
              {
                "\\[ \\text{NRR} = \\frac{\\text{Starting Revenue} + \\text{Expansion} - \\text{Churn} - \\text{Contraction}}{\\text{Starting Revenue}} \\times 100 \\]"
              }
            </MathJax>
            </Box>
          This calculation may be based upon ARR, ACV, run-rate revenue or reported revenue as the revenue basis.  
        </Typography>
        <Typography variant="body1" gutterBottom>
          A value above 100% indicates the company is growing revenue from its existing customer base despite churn, showcasing strong customer success and upsell strategies.
        </Typography>

        <Divider sx={{margin:'30px'}}/>
        {/* Net Expansion Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Net Expansion Rate (NER)
        </Typography>
        <Typography variant="body1" gutterBottom>
          NER isolates revenue expansion within the customer base by excluding churn and contraction. It focuses purely on upsell and cross-sell efforts from existing customers. This metric excludes revenue from new logos acquired in the last year. 
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
        <MathJax>
              {
                "\\[ \\text{NER} = \\frac{\\text{Starting Revenue} + \\text{Expansion}}{\\text{Starting Revenue}} \\times 100 \\]"
              }
            </MathJax>
            </Box>
          Similar to NRR, this metric leverages ARR, ACV, or revenue as the basis.
        </Typography>
        <Typography variant="body1" gutterBottom>
          NER reveals how effectively a company grows its wallet share among existing customers. High NER values reflect strong customer satisfaction and expansion opportunities.
        </Typography>
        <Divider sx={{margin:'30px'}}/>

        {/* ARR */}
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main}}>
          Annual Recurring Revenue (ARR)
        </Typography>
        <Typography variant="body1" gutterBottom>
          ARR represents the annualized value of recurring revenue contracts, typically encompassing both software and services. ARR is a measure of the amount of recurring revenue at a given point in time. ARR is typically measured by annualizing monthly revenue and the current period annual bill.  For multi-year contracts ARR is based upon the last billed annual amount. 
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{ARR} = \\text{Monthly Recurring Revenue (MRR)} \\times 12 \\ + \\text{Annual Contractual Value} \\]"}
          </MathJax>
          </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          ARR provides a clear picture of predictable revenue, helping evaluate the scalability of a company’s recurring revenue model.
        </Typography>
        <Divider sx={{margin:'30px'}}/>

        {/* Bookings */}
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main}}>
          Bookings Metrics
        </Typography>

        {/* RPO */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Remaining Performance Obligations (RPO)
        </Typography>
        <Typography variant="body1" gutterBottom>
          RPO is the total value of contracted revenue yet to be recognized. It includes both deferred revenue and unbilled revenue.
        </Typography>
        <Typography variant="body1" gutterBottom>
          RPO is disclosed in SEC filings and includes contracted revenue, billed and unbilled, expected to be recognized over the lifetime of customer agreements.
        </Typography>
        <Typography variant="body1" gutterBottom>
          RPO serves as a strong indicator of future revenue visibility and helps assess the business performance and lenght of customer contracts. Longer-term customer contracts is typical in enterprise and mission critical systems.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        {/* cRPO */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Current Remaining Performance Obligations (cRPO)
        </Typography>
        <Typography variant="body1" gutterBottom>
          cRPO represents the portion of RPO that is expected to be recognized within the next 12 months. It focuses on near-term revenue commitments. cRPO removes noise from changes in contract duration which affects the RPO metric.
        </Typography>
        <Typography variant="body1" gutterBottom>
          This is a subset of RPO filtered for obligations expected to be fulfilled within the next year. Our cRPO numbers are based upon the most precise available reported number, if disclosed as a value or calculated based upon a % of RPO in SEC fillings. 
        </Typography>
        <Typography variant="body1" gutterBottom>
          cRPO is a crucial metric for evaluating short-term financial health and forecasting near-term revenue streams.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        {/* Current Bookings */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Bookings and Current Bookings
        </Typography>
        <Typography variant="body1" gutterBottom>
          Bookings and Current Bookings measure the change in cRPO and RPO plus the current period's recognized revenue, providing insight into new sales activity within a given period.  This represents business contracted in the quarter and is leading indicator for recognized revenue.
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{Bookings} = \\Delta \\text{RPO} + \\text{Current Period Revenue} \\]"}
          </MathJax>
          </Box>
          <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{Current Bookings} = \\Delta \\text{cRPO} + \\text{Current Period Revenue} \\]"}
          </MathJax>
          </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          This metric highlights the effectiveness of sales teams in driving revenue commitments. This metric is volatile and can also be assessed on a trailing twelve month basis.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        
        {/* Bookings */}
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main}}>
          Billings Metrics
        </Typography>

        {/* Unearned Revenue */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Unearned Revenue (Current Portion)
        </Typography>
        <Typography variant="body1" gutterBottom>
          Unearned revenue is cash received for services or products not yet delivered or recognized as revenue. It is reported as a liability.
        </Typography>
        <Typography variant="body1" gutterBottom>
          This metric is derived directly from deferred revenue disclosures in financial filings.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Unearned revenue reflects obligations to customers which have been billed but not recognized. Our metrics report on the current portion of unearned revenue as multi-year billings are not market standard.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Calculated Billings (Current)
        </Typography>
        <Typography variant="body1" gutterBottom>
          Billings are a similar measure to bookings based upon invoices sent to customers. Billings will typically lag bookings as a result of the time it takes to invoice customers and differences in payment terms.  Billings can be an effective measure especially when current bookings are not disclosed. 
        </Typography>
        
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{Calculated Billings} = \\Delta \\text{Current Unearned Revenue} + \\text{Current Period Revenue} \\]"}
          </MathJax>
        </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          This metric highlights the effectiveness of sales teams in driving revenue commitments. This metric is volatile and can also be assessed on a trailing twelve month basis.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        {/* Payback Period */}
        <Typography variant="h5" gutterBottom sx={{  color: theme.palette.primary.main }}>
          Payback Period
        </Typography>
        <Typography variant="body1" gutterBottom>
          Payback period measures the time required to recoup customer acquisition costs (CAC) through revenue. It’s a critical metric for understanding go-to-market efficiency and long-term profitaiblity.  Sales efficiency is a key metric in scaling software businesses.
        </Typography>

        <Typography variant="body1" gutterBottom>
          This metric estimates the time required to recover sales and marketing expenses relative to growth in bookings or billings.  
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {
              "\\[ \\text{Payback Period} = \\frac{(\\text{YoY Growth TTM Current Bookings} \\times \\text{TTM GM %})}{\\text{TTM Sales & Marketing Expense}} \\times 12 \\text{ months} \\]"
            }
          </MathJax>
          </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Calculation is dynamically adjusted based upon data disclosed by company. Current bookings are used where available, followed by calculted billings, followed by revenue.
        </Typography>
   
        <Typography variant="body1" gutterBottom>
          A shorter payback period signifies efficient customer acquisition and quicker profitability, critical for cash flow management.
        </Typography>
   
        <Divider sx={{margin:'30px'}}/>
        {/* Free Cash Flow */}
        <Typography variant="h5" gutterBottom sx={{  color: theme.palette.primary.main }}>
          Cash Flow Metrics
        </Typography>
        <Typography variant="h6" sx={{ color: theme.palette.text.primary }} gutterBottom>
          Free Cash Flow (FCF)
        </Typography>
        <Typography variant="body1" gutterBottom>
          FCF measures the cash left over after operating expenses and capital expenditures. Capitalized software expenditures are included as a part of this calculation.
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{FCF} = \\text{Operating Cash Flow} - \\text{Capital Expenditures} - \\text{Capitalized Software} \\]"}
          </MathJax>
          </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          A positive FCF indicates healthy cash generation, while a negative FCF suggests heavy reinvestment or inefficiencies.
        </Typography>
        <Divider sx={{margin:'30px'}}/>

        {/* Additional metrics like Rule of Metrics, Payback Period, Cloud Mix, and Customers would be similarly expanded */}
         {/* Rule of Metrics */}
         <Typography variant="h5" gutterBottom sx={{  color: theme.palette.primary.main }}>
          Rule of Metrics
        </Typography>
        <Typography variant="body1" gutterBottom>
          The Rule of Metrics combines efficiency and growth to provide a holistic view of company performance. It highlights the balance between a company’s ability to grow revenue and its operational discipline, often used in the context of software or SaaS companies.  Rule of metrics allows for a comparison between the relationship of growth and profitability.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        {/* Rule of 40 */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Rule of 40
        </Typography>
        <Typography variant="body1" gutterBottom>
          The Rule of 40 is a benchmark for SaaS companies that balances growth with profitability. A company’s combined revenue growth rate and free cash flow (FCF) margin should ideally exceed 40%.  
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{Rule of 40} = \\text{TTM Revenue Growth} + \\text{TTM FCF Margin} \\]"}
          </MathJax>
          </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Achieving or exceeding 40% indicates a healthy balance between growth and profitability. It helps investors evaluate whether a company is prioritizing sustainable growth.
        </Typography>
        <Divider sx={{margin:'30px'}}/>

        {/* Rule of X (2x Revenue + FCF) */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Rule of X (2x Revenue + FCF)
        </Typography>
        <Typography variant="body1" gutterBottom>
          This metric weights revenue growth more heavily by multiplying it by two before adding the FCF margin. It emphasizes growth over profitability compared to the Rule of 40. Rule of X suggests that growth is a more important factor in creating shareholder value relative to margins.
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{Rule of X} = (\\text{TTM Revenue YoY Growth % } \\times 2) + \\text{TTM FCF Margin} \\]"}
          </MathJax>
          </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          This rule underscores the importance of rapid revenue growth in early-stage or high-growth companies, even at the cost of reduced profitability.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        {/* Rule of X (2x Current Bookings + FCF) */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Rule of X Bookings (2x Current Bookings + FCF)
        </Typography>

        <Typography variant="body1" gutterBottom>
          Rule of X Bookings is a variant of rule of X where the metrics is based upon current bookings rather than revenue. This metric can be useful where bookings provides a better indicator of growth than revenue.
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{Rule of X} = (\\text{TTM Current Bookings YoY Growth %} \\times 2) + \\text{TTM FCF Margin} \\]"}
          </MathJax>
          </Box>
          <Typography variant="body1" gutterBottom>
          Calculation is dynamically adjusted based upon data disclosed by company. Current bookings are used where available, followed by calculted billings, followed by revenue.
        </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          The metric measures a company’s growth efficiency based on near-term revenue commitments, ideal for assessing companies reliant on bookings growth.
        </Typography>
        <Divider sx={{margin:'30px'}}/>


        {/* Cloud Revenue */}
        <Typography variant="h5" gutterBottom sx={{  color: theme.palette.primary.main }}>
          Cloud Revenue
        </Typography>

        {/* Cloud Revenue */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Cloud Revenue and Cloud ARR
        </Typography>
        <Typography variant="body1" gutterBottom>
          Cloud revenue refers to income generated from SaaS and managed first-party services. It is distinct from non-cloud revenue, which includes services or traditional software models.
        </Typography>
        <Typography variant="body1" gutterBottom>
          This is typically disclosed in financial statements as a subset of total revenue or as a part of ARR. 
        </Typography>

        <Divider sx={{margin:'30px'}}/>
        {/* Cloud Mix */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Cloud Mix
        </Typography>
        <Typography variant="body1" gutterBottom>
          Cloud mix represents the proportion of revenue or ARR derived from cloud-based offerings versus traditional or on-premise solutions. Cloud mix is based upon revenue where disclosed or ARR if cloud revenue is not disclosed.
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
          <MathJax>
            {"\\[ \\text{Cloud Mix} = \\frac{\\text{Cloud Revenue}}{\\text{Total Revenue}} \\times 100 \\]"}
          </MathJax>
          </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          A high cloud mix signifies a company’s success in transitioning to cloud-based solutions, which may offer higher growth potential.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        
        {/* Customers */}
        <Typography variant="h5" gutterBottom sx={{  color: theme.palette.primary.main }}>
          Customers
        </Typography>
        <Typography variant="body1" gutterBottom>
          Customer metrics provide insights into the scale and quality of a company’s customer base average spend per customer. 
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Total Customers
        </Typography>
        <Typography variant="body1" gutterBottom>
          Total customers represent all paying customers or a specific subset, such as those meeting a minimum revenue or ARR threshold. All customer metrics align to the company disclosed definition. Typical thresholds compared are where customer spend is greater than 100k and greater than $1M.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Tracking total customers provides an overview of market penetration and growth. Total customer growth is typically driven by technology adoption especially in smaller customers. Customer count above a threshold is a better indicator for upmarket adoption.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Revenue per Customer
        </Typography>
        <Typography variant="body1" gutterBottom>
          Revenue per customer is calculated based upon  TTM current bookings divided by last quarterly disclosed customer count number.
        </Typography>
        <Typography variant="body1" gutterBottom>
        <Box sx={{fontSize:formulaSize}}>
        <MathJax>
            {"\\[ \\text{Revenue per Customer} = \\frac{\\text{TTM Current Bookings}}{\\text{Last Quarter Total Customers}}  \\]"}
          </MathJax>
        </Box>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Calculation is dynamically adjusted based upon data disclosed by company. Current bookings are used where available, followed by calculted billings, followed by revenue.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Tracking revenue per customer provides insight into customer spend and changes in customer segment mix.
        </Typography>
        
        <Divider sx={{margin:'30px'}}/>
      </Box>
      
    </MathJaxContext>
  );
};

export default Documentation;
