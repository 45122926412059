import { ResponsiveLine } from '@nivo/line';
import React, { useEffect, useState } from 'react';
import {Paper,Box } from '@mui/material';
const KeyBusinessMetricsChart = ({ data,field,theme}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isDarkMode = theme.palette.mode === 'dark';
    useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to determine the number of ticks based on window width
  const getTickStep = () => {
    if (windowWidth < 600) return 4; // On small screens, show every 4th label
    if (windowWidth < 900) return 2; // On medium screens, show every 2nd label
    return 1; // On large screens, show all labels
};

// Function to filter tick values based on step
const filterTicks = (allTicks) => {
    const step = getTickStep();
    return allTicks.filter((_, index) => index % step === 0); // Skip labels based on step
};

// Assuming x-axis data are the labels (e.g., dates, years)
const allXTicks = data[0].data.map(point => point.x);

    const themeGraph = {
      textColor: isDarkMode ? 'white' : 'black',
      grid: {
        line: {
          stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
      },
      axis: {
        legend: {
            text: {
                fill: isDarkMode ? 'white' : 'black',
            },
        },
        ticks: {
            text: {
                fill: isDarkMode ? 'white' : 'black',

            },
        },
    },
      markers: {
        lineColor: isDarkMode ? 'white' : 'black',
        textColor: isDarkMode ? 'white' : 'black',
      },
      tooltip: {
        container: {
          background: isDarkMode ? '#323232' : '#FFFFFF', // Dark or light mode background color
          color: isDarkMode ? 'white' : 'black', // Text color in the tooltip
        },
      },
      crosshair: {
        line: {
          stroke: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)', // Crosshair color
          strokeWidth: .5, // Line thickness
          strokeDasharray: '4 4', // Optional: Dashed line style
        },
      },
    };
    const minYValue = Math.min(...data.map((d) => Math.min(...d.data.map((p) => p.y))));
  
    const yScaleSettings = {
      type: 'linear',
      min: minYValue < 0 ? minYValue *1.1 : 0, // Add some space if minYValue is negative
      max: 'auto',
      stacked: false,
      reverse: false,
    };
    const formatValue = (value) => {
      if (value ===""){
        return value
      }
      const absValue = Math.abs(value);
      
      if (absValue >= 1e11) {
        // Format as billions with three significant digits
        return `${(value / 1e9).toFixed(0)}B`;
      }else if (absValue >= 1e9) {
        // Format as billions with three significant digits
        return `${(value / 1e9).toFixed(1)}B`;
      } else if (absValue >= 1e7) {
          // Format as billions with three significant digits
          return `${(value / 1e6).toFixed(0)}M`;
      } else if (absValue >= 1e6) {
        // Format as millions with three significant digits
        return `${(value / 1e6).toFixed(1)}M`;
      } else if (absValue >= 1e3) {
        // Format as thousands with three significant digits
        return `${(value / 1e3).toFixed(1)}K`;
      } else if (absValue >= 3) {
        // Format as is with three significant digits
        return value.toFixed(2);
      }
      else if (absValue === 0) {
        return value.toFixed(0);
      }
      else if (absValue >= 1){
        
        return `${(value * 100).toFixed(0)}%`;
      }
      else {
        return `${(value * 100).toFixed(1)}%`;
      }
    };

    const CustomTooltip = ({ point }) => {
      const currentIndex = point.index;
      const currentValue = point.data.y;
      const currentPeriod = point.data.x;
      const fullData = point.serieId ? data.find((d) => d.id === point.serieId).data : [];
      
      if (!fullData || fullData.length === 0) {
        return <div>No data available</div>;
      }
    
      // Calculate YoY Change (4 quarters ago)
      const prevYearIndex = currentIndex - 4;
      const yoyValueChange =
        prevYearIndex >= 0 ? currentValue - fullData[prevYearIndex].y : null;
      const yoyPercentChange =
        prevYearIndex >= 0
          ? ((yoyValueChange / fullData[prevYearIndex].y) * 100).toFixed(2)
          : null;
    
      // Calculate QoQ Change (previous quarter)
      const prevQuarterIndex = currentIndex - 1;
      const qoqValueChange =
        prevQuarterIndex >= 0 ? currentValue - fullData[prevQuarterIndex].y : null;
      const qoqPercentChange =
        prevQuarterIndex >= 0
          ? ((qoqValueChange / fullData[prevQuarterIndex].y) * 100).toFixed(2)
          : null;
    
       // Determine half of the data
      const isInFirstHalf = currentIndex < fullData.length / 2;

      // Position the tooltip based on the half
      const tooltipStyle = {
        marginLeft: isInFirstHalf ? '120px' : '-120px', // Shift tooltip left or right
        marginRight: isInFirstHalf ? 'auto' : '10px',
        marginBottom:'-8px'
      };
    
      return (
        <Paper elevation={4} style={{ position: 'relative', ...tooltipStyle }}>
          <Box sx={{ width: '100%', backgroundColor: theme.tableHeader.highlightDark }}>
            <Box style={{ color:theme.palette.text.primary,paddingLeft: '8px', paddingRight: '8px',paddingTop:'4px',paddingBottom:'4px', fontWeight: 'bold', fontSize: '11px' }}>
              <span>{currentPeriod}: </span>{formatValue(currentValue)}
            </Box>
          </Box>
          <Box sx={{ paddingLeft: '8px', paddingBottom: '8px', paddingRight: '8px', paddingTop: '2px' }}>
            <div style={{ fontWeight: 500, fontSize: '13px', marginBottom: '2px' }}></div>
            <div style={{ fontSize: '11px', color: theme.palette.text.secondary }}>
              <span>
                <span>YoY: </span> 
                {yoyValueChange !== null ? (
                  <span style={{ color: yoyValueChange >= 0 ? '#006400' : '#8B0000' }}>
                    {yoyValueChange >= 0 ? '+' : ''}{formatValue(yoyValueChange)} | {yoyPercentChange >= 0 ? '+' : ''}{yoyPercentChange}%
                  </span>
                ) : 'N/A'}
              </span>
              <br />
              <span>
                <span>QoQ: </span> 
                {qoqValueChange !== null ? (
                  <span style={{ color: qoqValueChange >= 0 ? '#006400' : '#8B0000' }}>
                    {qoqValueChange >= 0 ? '+' : ''}{formatValue(qoqValueChange)} | {qoqPercentChange >= 0 ? '+' : ''}{qoqPercentChange}%
                  </span>
                ) : 'N/A'}
              </span>
            </div>
          </Box>
        </Paper>
      );
    };
  
    return (
  
    <ResponsiveLine
        data={data}
        margin={{ top: 15, right: 50, bottom: 40, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={yScaleSettings}
        yFormat={(value) => formatValue(value,field)}
        curve="step"
        axisTop={null}
        axisLeft={{
          format: (value) => formatValue(value,field),
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: field,
          legendOffset: -50,
          legendPosition: 'middle',
        }}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 50,
            orient:'bottom',
            legend: 'Year',
            legendOffset: 80,
            legendPosition: 'middle',
            tickValues: allXTicks.length > 10 ? filterTicks(allXTicks) : allXTicks,
        }}
        enableGridX={false}
        enableGridY={false}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaBaselineValue={0}
        useMesh={true}
        animate={true}
        enableCrosshair={true}
        motionConfig="gentle"
        tooltip={CustomTooltip}
        theme={themeGraph} // Apply the custom dark mode them
    />
  )
      }

      export default KeyBusinessMetricsChart;