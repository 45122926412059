import React, { useState, useEffect,useMemo } from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {FormControl,useMediaQuery,Collapse,Divider, Box, Typography,IconButton,MenuItem,Select, CircularProgress} from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ButtonTimeFrame from '../components/ButtonTimeFrame';
import ButtonFormatValues from '../components/ButtonFormatValues';
import FilterSlider from '../components/FilterSlider';
import TableTrended from '../components/TableTrended';
import TableMetrics from '../components/TableMetrics';
import GraphTrended from '../components/GraphTrended';
import FilterTableFields from "../components/FilterTableFields.jsx";
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const SecurityAnalysisKeyMetrics = ({open,handleToggleCollapse,ticker}) => {  
  
  const { logout,isAuthenticated, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const apiUrl = 'https://mwl26uv0m7.execute-api.us-east-1.amazonaws.com/prod/validate'; // Use the API Gateway URL from CDK output
    const fileKey = `securityanalysis/financial/${ticker}.json`
    useEffect(() => {
      const fetchAuthData = async () => {
        if (!isAuthenticated) return; // Wait until the user is authenticated
      
        setLoading(true);
        try {
          // Retrieve the token from Auth0
          const token = await getAccessTokenSilently({
            audience: "https://api.softwareiq.io", // Replace with your Auth0 audience
            scope: "read:news", // Replace with your API's required scopes
          });
      
          // Make the API call with the token and fileKey
          const response = await axios.get(`${apiUrl}?fileKey=${fileKey}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          const financialData = response.data.data
          const dynamicDataAnnual = financialData.annualData
          const dynamicDataQtr = financialData.quarterlyData
          const dynamicKeyStats = financialData.keyStats

          const keyStatsFilterCriteria = ['Market Cap','Enterprise Value', 'EV / Revenue', 'EV / GM','Price / FCF','Close Date']; // Example metrics to include
          if (Array.isArray(dynamicDataAnnual.data)) {
            setAnnualData(dynamicDataAnnual.data);
            setAnnualMapping(dynamicDataAnnual.mappingTable);
            // Check the last revenue value and set the scale to 'B' if it's greater than $10B
            const revenueData = dynamicDataAnnual.data.find(item => item.field === 'Revenue');
            if (revenueData) {
              const lastRevenue = revenueData[Object.keys(revenueData).pop()]; // Get the last value
              if (lastRevenue > 2000000000) { // Check if the last revenue is greater than $10B
  
                setAlignmentScale('B');
              }
            }
          }
          else {
            console.error('Unexpected data format:', dynamicDataAnnual);
          } 
          if (Array.isArray(dynamicDataQtr.data)) {
            setQtrData(dynamicDataQtr.data);
            setQtrMapping(dynamicDataQtr.mappingTable);
            setReportingCurrency(dynamicDataQtr.currency);
          }
          else {
            console.error('Unexpected data format:', dynamicDataQtr);
          }
          if (Array.isArray(dynamicKeyStats)) {
            setKeyStats(dynamicKeyStats.filter(item =>
              keyStatsFilterCriteria.includes(item.field)));
          }
          else {
            console.error('Unexpected data format:', dynamicKeyStats);
          }
        } catch (error) {
          console.error("Error fetching data", error);
      
          if (
            error.message.includes("Missing Refresh Token") ||
            error.error === "login_required"
          ) {
            // Force the user to sign out
            logout({ returnTo: window.location.href });
          } else {
            setError("Error fetching data");
          }
        } finally {
          setLoading(false);
        }
      };
  
      fetchAuthData();
    }, [isAuthenticated,ticker, getAccessTokenSilently]);

  const theme = useTheme();
  const allColDefs = [
    { field: "Revenue",group:"Revenue Metrics"},
    { field: "Cloud ARR",group:"Revenue Metrics"},
    { field: "Non-Cloud ARR",group:"Revenue Metrics"},
    { field: "Creative ARR",group:"Revenue Metrics"},
    { field: "Document Cloud ARR",group:"Revenue Metrics"},
    { field: "Digital Media ARR",group:"Revenue Metrics"},

    { field: "ARR",group:"Revenue Metrics"},
    { field: "Calculated Billings",group:"Revenue Metrics"},
    { field: "Current Bookings",group:"Revenue Metrics"},
    { field: "Bookings",group:"Revenue Metrics"},  
    { field: "Current RPO",group:"Revenue Metrics"},
    { field: "RPO",group:"Revenue Metrics"},
    { field: "Cloud Revenue",group:"Revenue Metrics"},
    { field: "Non-Cloud Revenue",group:"Revenue Metrics"},
    { field: "Rule of 40",group:"Business Metrics"},
    { field: "Net Retention Rate",group:"Business Metrics"},
    { field: "Current RPO Mix",group:"Business Metrics"},
    { field: "RPO Duration",group:"Business Metrics"},
    { field: "Customers",group:"Business Metrics"},
    { field: "Customers >$5k",group:"Business Metrics"},
    { field: "Customers >$10k",group:"Business Metrics"},
    { field: "Customers >$50k",group:"Business Metrics"},
    { field: "Customers >$100k",group:"Business Metrics"},
    { field: "Customers >1M",group:"Business Metrics"},
    { field: "Payback Period",group:"Business Metrics"},
    { field: "Cloud Mix",group:"Business Metrics"},
    { field: "COGs",group:"Profit"},
    { field: "Gross Margin",group:"Profit"},
    { field: "Sales & Marketing",group:"Profit"},
    { field: "SG&A",group:"Profit"},
    { field: "Research & Development",group:"Profit"},
    { field: "General & Administrative",group:"Profit"},
    { field: "Operating Income",group:"Profit"},
    { field: "Free Cash Flow",group:"Profit"},
    { field: "Net Income",group:"Profit"},
    { field: "Shares Outstanding",group:"Profit"},
  ];
  const isTablet = useMediaQuery('(max-width:1080px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  const isPhablet =useMediaQuery('(max-width:700px)');
  const [annualData, setAnnualData] = useState([]);
  const [qtrData, setQtrData] = useState([]);
  const [keyStats, setKeyStats] = useState([]);
  const [annualMapping,setAnnualMapping] = useState([]);
  const [qtrMapping,setQtrMapping] = useState([]);
  const [reportingCurrency,setReportingCurrency] = useState(["USD"]);
  const [alignment, setAlignment] = React.useState('annual');
  const [alignmentGraph,setAlignmentGraph] = useState('quarterly')
  const [selectedTrendedMetric, setSelectedTrendedMetric] = useState('Revenue');
  const [metrics,setMetrics] = useState(['ARR','Cloud ARR','Non-Cloud ARR','Creative ARR','Document Cloud ARR','Digital Media ARR','Calculated Billings','Current Bookings','Bookings','Current RPO','RPO','Non-Cloud Revenue','Cloud Revenue','Revenue','Cloud Mix','RPO Duration','Current RPO Mix','Customers','Customers >$100k','Customers >$1M','Payback Period','Rule of 40','Net Retention Rate'])
  const [nivoData, setNivoData] = useState([])
  const [mappingArray, setMappingArray] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterColDef, setFilteredColDef] = useState([])

  const handleColumnsChange = (event) => {
    const { value } = event.target;

    if (Array.isArray(value)) {
      setMetrics(value);
      return;
    }}

  const handleChange = (event, newAlignment) => {
      if (newAlignment !== null){
        setAlignment(newAlignment);
        setAlignmentGraph(newAlignment)
      }
    };
    const handleChangeGraph = (event, newAlignment) => {
      if (newAlignment !== null){
        setAlignmentGraph(newAlignment);
      }
    };

    const handleChangeScale = (event, newAlignment) => {
    
      if (newAlignment !== null){
        setAlignmentScale(newAlignment);
      }
    };
    
    
    
  
  const [alignmentScale, setAlignmentScale] = React.useState('M');
  
 

  const calculateMaxSliderValue = () => {
    const mapping = alignment === 'annual' ? annualMapping : qtrMapping;
    return Math.min(mapping.length, 20);
  };
  
  const maxSliderValue = calculateMaxSliderValue();
  const initialSliderValue = Math.min(isMobile ? 2 : isTablet ? Math.min(5, maxSliderValue) : Math.min(8, maxSliderValue), maxSliderValue);
  const [sliderValue, setValue] = useState(initialSliderValue);
  
  


  useEffect(() => {
    const maxSliderValue = calculateMaxSliderValue();
    const initialSliderValue = isMobile ? 2 
                          : isTablet ? Math.min(5, maxSliderValue) 
                          : Math.min(8, maxSliderValue);
    setValue(initialSliderValue);
  }, [annualData]);


  
  useEffect(()=>{
    const slicedMapping = alignment === 'annual' 
    ? annualMapping.slice(-sliderValue) 
    : alignment === 'quarterly' 
    ? qtrMapping.slice(-sliderValue) 
    : [];

  const qtrGraphSlicedMapping = qtrMapping.slice(-4 * sliderValue);
  const filteredAnnualData = annualData.filter(item => metrics.includes(item.field));
  const filteredQtrData = qtrData.filter(item => metrics.includes(item.field));
  
  const filteredColDefAnnual = allColDefs.filter(item =>
    annualData.some(data => data.field === item.field)
  );
  
  const filteredColDefQuarter = allColDefs.filter(item =>
    qtrData.some(data => data.field === item.field)
  );

  setMappingArray(slicedMapping);
  setFilteredColDef(alignment === 'annual' ? filteredColDefAnnual: alignment === 'quarterly' ? filteredColDefQuarter : []);
  setFilteredData(alignment === 'annual' ? filteredAnnualData : alignment === 'quarterly' ? filteredQtrData : []);

  const graphFilteredData = alignmentGraph === 'annual' ? annualData : alignmentGraph === 'quarterly' ? qtrData : [];
  const graphMappingArray = alignment === 'annual' 
    ? (alignmentGraph === 'annual' ? slicedMapping : qtrGraphSlicedMapping) 
    : alignment === 'quarterly' 
    ? qtrMapping.slice(-sliderValue) 
    : [];
  const convertToNivoFormat = (field) => {
    const filtered = graphFilteredData.find(item => item.field === field);
  if (!filtered) return [];

  // Filter the data based on the periods in annualSlicedMapping and format it for Nivo
  
  const formattedData = {
    id: field,
    data: Object.keys(filtered)
      .filter(key => key !== 'field' && graphMappingArray.some(mapping => mapping.period === key))
      .map(key => ({
        x: key,
        y: filtered[key]
      }))
  };

  return [formattedData];
  };
  setNivoData(convertToNivoFormat(selectedTrendedMetric));
},[annualData,sliderValue,alignment,alignmentGraph,selectedTrendedMetric,metrics])

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);

  };
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = () => {
    if (sliderValue < 2 || isNaN(sliderValue)) {
      setValue(2);
    } else if (sliderValue > maxSliderValue) {
      setValue(maxSliderValue);
    }
  };
  useEffect(() => {
    const maxSliderValue = calculateMaxSliderValue();
    const initialSliderValue = isMobile ? 2 
                          : isTablet ? Math.min(5, maxSliderValue) 
                          : Math.min(8, maxSliderValue);
    setValue(initialSliderValue);
  }, [annualData]);
  
  const valueFormatter = (params) => {
    const metricName = params.data.field; // The metric name
    const currString = reportingCurrency === 'USD' ? "$" : "€"
    const value = params.value;
    if (value === null || value === undefined) {
      return '-';  // Display 'N/A' for missing values
    }
    // Define your conditional formatting rules
    if (metricName === "Revenue" || metricName === "ARR" ||metricName === "Creative ARR" ||metricName === "Document Cloud ARR" ||metricName === "Digital Media ARR" || metricName === "Cloud ARR" || metricName === "Non-Cloud ARR" || 
      metricName === "Cloud Revenue" || metricName === "Non-Cloud Revenue" || metricName === "Calculated Billings" || 
      metricName === "Current Bookings" || metricName === "Bookings" || metricName === "Current RPO" || metricName === "RPO" || 
      metricName === "COGs" || metricName === "Gross Margin" || metricName === "Sales & Marketing" || 
      metricName === "Research & Development" || metricName === "General & Administrative" || 
      metricName === "Operating Income" || metricName === "Net Income" || metricName === "Free Cash Flow") {
  
      const isNegative = value < 0;
      const absValue = Math.abs(value);  // Use the absolute value for formatting
      
      // Format based on alignmentScale
      if (alignmentScale === "M") {
          // Millions logic: 1 decimal place if < 100 million, otherwise no decimals
          const formattedValue = absValue < 100e6 
              ? absValue / 1e6 
              : Math.round(absValue / 1e6); // Remove decimals if >= 100M
          const decimalPlaces = absValue < 100e6 ? 1 : 0;
  
          return `${isNegative ? '-' : ''}${currString}${formattedValue.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces })}M`;
  
      } else {
          // Billions logic: 2 decimal places if < 10 billion, 1 decimal if < 100 billion, no decimals if > 100 billion
          let formattedValue;
          let decimalPlaces;
  
          if (absValue < 10e9) {
              formattedValue = absValue / 1e9;
              decimalPlaces = 2;
          } else if (absValue < 100e9) {
              formattedValue = absValue / 1e9;
              decimalPlaces = 1;
          } else {
              formattedValue = Math.round(absValue / 1e9); // Remove decimals if >= 100B
              decimalPlaces = 0;
          }
  
          return `${isNegative ? '-' : ''}${currString}${formattedValue.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces })}B`;
      }
  
  

    } else if (metricName ==="Rule of 40" || metricName ==="Cloud Mix"|| metricName ==="Net Retention Rate") {
      // Format as a percentage (%)
      if (value >1){
      
        return `${(value*100).toFixed(0)}%`;
      } else{
      return `${(value*100).toFixed(1)}%`;
    }
    }
    else if (metricName ==="Shares Outstanding" ) {
      // Format as a percentage (%)
      return `${(value / 1e6).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`
    }
    else if (metricName ==="Customers" || metricName ==="Customers >$100k"|| metricName ==="Customers >$1M") {
      // Format as a percentage (%)
      return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
  

    // Default formatting (if no specific rule applies)
    return value.toFixed(1);
  };
  const columnDefs = useMemo(() => {
    const columns = [
      { headerName: alignment === 'annual' ? 'Fiscal Year' : 'Fiscal Quarter',
        headerComponentParams: { template: `<div class="ag-header-cell-label"> <span class="ag-header-cell-text"> <strong>${alignment === 'annual' ? 'Fiscal Year' : 'Fiscal Quarter'}</strong><br/> <span style="font-size: 0.8em;">Period Ended</span> </span> </div>`, },
        headerClass: "left-align-header",  
        cellStyle: { paddingRight: '0px' }, 
        
        pinned:'left',width:190, field: "field", sortable: false, filter: false,rowDrag:true }
    ];

    // Dynamically add fiscal year columns
    mappingArray.forEach(mapping => {
      columns.push({
        headerName: mapping.period, // Use "period" as the column header
        headerComponentParams: { template: `<div class="ag-header-cell-label"> <span class="ag-header-cell-text"> <strong>${mapping.period}</strong><br/> <span style="font-size: 0.8em;">${mapping.endDate}</span> </span> </div>`, },
        headerClass: "right-align-header",  
        field: mapping.period,          // Field matches the fiscal year (e.g., FY21, FY22)
        sortable: false,
        filter: false,
        type: "numericColumn",
        width:100,
        valueFormatter
      });
    });
    return columns;
  }, [mappingArray,alignmentScale,metrics]);
  useEffect(() => {
    // Calculate and set tableWidth whenever columnDefs changes
    const totalWidth = columnDefs.reduce((sum, colDef) => sum + colDef.width+1, 0) + 12;
    setTableWidth(totalWidth);
  }, [columnDefs]); // Add columnDefs as a dependency
  
  const [tableWidth, setTableWidth] = useState([columnDefs.reduce((sum, colDef) => sum + colDef.width+1, 0) + 12]);


  return(
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <Box
      sx={{
        height:'auto',
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
        <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
          <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <Box sx={{ display: 'flex', width:'1350px',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              maxWidth: '1500px',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile?'100%':'65px',
                width: isMobile?'100%':'65px',
                fontSize: '12px',
                textAlign: isMobile?'left':'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Dashboard Filters
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <ButtonTimeFrame alignment={alignment} handleChange={handleChange}/>
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <ButtonFormatValues alignment={alignmentScale} handleChange={handleChangeScale}/>
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
                <FilterTableFields
                selectedColumns={metrics}
                handleColumnsChange={handleColumnsChange}
                allColDefs={filterColDef}
                label={'Filter Fields'}
                />
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <FilterSlider period={alignment === "annual" ? "Years" : "Quarters"} sliderValue={sliderValue} maxSliderValue={maxSliderValue} handleSliderChange={handleSliderChange} handleInputChange={handleInputChange} handleBlur={handleBlur}/>
              </Box>
              <Box
              sx={{
                maxWidth: isMobile?'100%':'65px',
                width: isMobile?'100%':'65px',
                fontSize: '12px',
                textAlign: isMobile?'left':'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Chart Filters
            </Box>
            <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <ButtonTimeFrame alignment={alignmentGraph} handleChange={handleChangeGraph}/>
              </Box>
              <Box sx={{marginTop:'4px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <Box sx={{ height:'30px', width: '150px' }}>
            <FormControl sx={{ height:'30px',paddingLeft: '0%', width: '170px' }}>
              <Select
                value={selectedTrendedMetric}
                onChange={(event) => setSelectedTrendedMetric(event.target.value)}
                renderValue={(value) => (
                  <Typography
                    variant="body2"
                    sx={{
                      
                      fontSize: '12px',
                      display: 'flex',
                      alignContent: 'center',
                      paddingTop: '3px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {value}
                  </Typography>
                )}
                displayEmpty
                size="small"
                sx={{height: '30px',backgroundColor:theme.tableHeader.highlight}}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                
                {filteredData.map((item, index) => (
                <MenuItem sx={{ fontSize:'13px'}} key={index} value={item.field}>
                  {item.field}
                </MenuItem>
              ))}
              </Select>
            </FormControl>

          </Box>
          </Box>
          
            </Box>
          </Box>
        </Box>


        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
    </Box>
    </Collapse>
    </Box>
    
    {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress /> {/* This is the MUI spinner */}
      </Box>):
      (        
  <Box sx={{flexGrow: 1, width:'100%'}}>
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'250px',height:'45%',maxHeight:'45%',width:'100%',maxWidth:'100%'}}>

 
      {!isPhablet&&<Box  sx={{ marginLeft:'1%',height: '100%',width: '230px', marginBottom: '0px'}}>
        <TableMetrics keyStats={keyStats} />
      </Box>}  
      <Box  sx={{ height: '100%',maxWidth: isPhablet?'100%':'calc(100% - 230px)', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
      {nivoData && nivoData.length > 0 &&sliderValue >0 ?<GraphTrended nivoData={nivoData} selectedTrendedMetric={selectedTrendedMetric}/>:"Loading"}
      </Box>

        </Box>
        
  <Box elevation={3} sx={{ display:'flex',minHeight:'250px',height: '50%', maxHeight: '50%', marginTop: '0px', display: 'flex', flexDirection: 'column' }}>
  <Box sx={{ paddingBottom:'.5%',display: 'flex', justifyContent: 'center', width: '100%',marginBottom:'8px', }}>
    <Divider sx={{   width: tableWidth -100,maxWidth:'80%' }} />
  </Box>
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', maxHeight: '100%', width: '100%' }}>
    {/* Center the table container */}
    <Box sx={{ maxWidth: isTablet ?'100%':'80%', height: '100%', maxHeight: '100%' }}>
      <TableTrended
        ticker = {ticker}
        alignmentScale={alignmentScale}
        columnDefs={columnDefs}
        filteredData={filteredData}
        tableWidth={tableWidth}
        setSelectedTrendedMetric={setSelectedTrendedMetric}
      />
    </Box>
  </Box>
</Box>

 
    </Box>)}

    </Box>

      )}
      export default SecurityAnalysisKeyMetrics;