import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from "@nivo/line";
import { isValid } from 'date-fns';

const MyResponsiveLine = ({ data, theme }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showLegend, setShowLegend] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Dynamically set the legend visibility based on window width and number of series
    useEffect(() => {
        const dynamicThreshold = (114 * data.length)+40; // Set a dynamic threshold based on number of series
        setShowLegend(windowWidth >= dynamicThreshold); // Hide legend if window width is less than threshold
    }, [windowWidth, data.length]);

    const isDarkMode = theme.palette.mode === 'dark';

    const themeGraph = {
        textColor: isDarkMode ? 'white' : 'black',
        grid: {
            line: {
                stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            },
        },
        axis: {
            legend: {
                text: {
                    fill: isDarkMode ? 'white' : 'black',
                    fontWeight: 'bold',
                },
            },
            ticks: {
                text: {
                    fill: isDarkMode ? 'white' : 'black',
                },
            },
        },
        markers: {
            lineColor: isDarkMode ? 'white' : 'black',
            textColor: isDarkMode ? 'white' : 'black',
        },
        tooltip: {
            container: {
                background: isDarkMode ? '#323232' : '#FFFFFF',
                color: isDarkMode ? 'white' : 'black',
            },
        },
        legends: {
            text: {
                fill: isDarkMode ? 'white' : 'black',
            },
        },
    };

    const formatValue = (value) => {
        if (value === 0) {
            return undefined; // Exclude zeros
        }
        if (value >= 1e4) {
            return `${(value / 1e3).toFixed(0)}k`;
        } else if (value >= 1e3) {
            return `${(value / 1e3).toFixed(1)}k`;
        } else {
            return `${(value).toFixed(0)}`;
        }
    };

    // Preprocess the data to format the dates and exclude zero values
    const formattedData = data.map(serie => ({
        id: serie.id,
        data: serie.data
            .filter(point => point.y !== 0) // Exclude points with zero values
            .map(point => ({
                x: point.x, // Keep original x values
                y: point.y // Keep original y values
            }))
    }));

    const customFormatDate = (date) => {
        if (!isValid(date)) return ''; // Handle invalid dates
        const month = date.getMonth() + 1; // getMonth returns 0-11
        const day = date.getDate(); // getDate returns 1-31
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        return `${month}/${day}/${year}`; // Construct the date as M/D/YY
    };

    return (
        <ResponsiveLine
            data={formattedData} // Use the formatted data
            theme={themeGraph}
            margin={{ top: 0, right: 20, bottom: 50, left: 40 }}
            colors={theme.palette.mode === 'dark' ? { scheme: 'spectral' } : { scheme: 'spectral' }}
            xScale={{
                type: 'time',
                format: '%m-%d-%Y',
                useUTC: false,
                precision: 'day'
            }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 'auto',
                stacked: false,
                reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: windowWidth < 600 ? -45 : 0, // Rotate the labels on small screens
                format: (date) => customFormatDate(date), // Use custom date formatting
                legendOffset: 36,
                legendPosition: 'middle',
                ticks: windowWidth < 600 ? 5 : 10, // Display fewer ticks on smaller screens
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'EV / Sales NTM',
                legendOffset: -35,
                legendPosition: 'middle',
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableGridX={false}
            enableGridY={true}
            enablePoints={false}
            enableArea={true}
            enableSlices="x"
            areaOpacity={0.05}
            areaBaselineValue={0}
            // Conditionally hide the legend based on window width and number of data series
            legends={showLegend ? [{
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -4,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 124,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [{
                    on: 'hover',
                    style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1
                    }
                }]
            }] : []} // Empty array to hide the legend when showLegend is false
        />
    );
};

export default MyResponsiveLine;
