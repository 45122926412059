import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  Box,
  Drawer,
  List,
  Toolbar,
  ListItem,
  ListItemText,
  useMediaQuery,
  Link as MuiLink, // MUI's Link component for styling
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { Outlet } from 'react-router-dom';

function Documentation() {
  const theme = useTheme();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [heightUnit, setHeightUnit] = useState('vh');
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { label: "Metric Calculation", link: "/documentation/metrics" },
    { label: "Category Definitions", link: "/documentation/categories" },
    // Add more menu items as needed
  ];

  return (
    <Box 
      sx={{ 
        height: `calc(100${heightUnit} - 100px)`,
        display: 'flex', 
        flexDirection: 'row', // Use row to align the drawer and content horizontally
        overflowY: 'auto', 
        flexGrow: 1,
      }}
    >
      {/* Drawer */}
      <Drawer
        variant={isDesktop ? "permanent" : "temporary"}
        open={isDesktop || drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          zIndex: 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "220px", // Set drawer width
            boxSizing: 'border-box',
            backgroundColor: theme.background.default,
          },
        }}
      >
        <Toolbar />
        <Box sx={{ paddingTop: '30px' }}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                key={item.label}
                sx={{
                  color: theme.palette.text.secondary,
                  backgroundColor: location.pathname === item.link ? theme.palette.action.selected : 'transparent', // Highlight active item
                  "&:hover": {
                    backgroundColor: location.pathname === item.link ? theme.palette.action.selected : theme.palette.action.hover, // Optional: Different hover effect when active
                  },
                  // Ensure the ListItem behaves like a button
                  padding: 0, // Remove any padding around the ListItem so the link is fully clickable
                }}
              >
                {/* Wrap the entire ListItem with MUI Link */}
                <MuiLink
                  component={Link} // Using react-router's Link for navigation
                  to={item.link}
                  sx={{
                    display: 'block', // Make the entire link area fill the ListItem
                    width: '100%', // Ensure the link takes up the full width
                    textDecoration: 'none', // Optional: remove underline from links
                    color: 'inherit', // Inherit color from parent
                    padding: theme.spacing(1), // Add padding for clickable area
                    "&:hover": {

                    },
                  }}
                >
                  <ListItemText primary={item.label} />
                </MuiLink>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Main content */}
      <Box
        sx={{
          flexGrow: 1,
          padding: theme.spacing(3),
          marginLeft: isDesktop ? "220px" : "0px", // Offset for drawer, 0 when mobile/tablet
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100%',
          alignItems: 'top', // Center content vertically
        }}
      >
        <Box sx={{ maxWidth: '1000px', width: '100%', textWrap: 'wrap' }}>
          <Outlet />
          {/* Add more documentation content here */}
        </Box>
      </Box>
    </Box>
  );
}

export default Documentation;
