import React from "react";
import { Box, Typography, List, ListItem, Divider, useTheme } from "@mui/material";

const Documentation = () => {
  const theme = useTheme();


  return (
      <Box
        sx={{
          paddingTop:'0px',
          color: theme.palette.text.secondary,
          fontSize: "0.9rem", // Base font size for all text
          "& h4": { fontSize: "2rem" }, // Scale down headings
          "& h5": { fontSize: "1.4rem" },
          "& h6": { fontSize: ".9rem" },
          "& p, & span, & div": { fontSize: ".9rem" }, // Scale down paragraphs and MathJax
          "& > *": { marginBottom: "16px" }, // Apply spacing between elements
        }}
      >
        
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
          Category Definitions
        </Typography>

        <Divider sx={{marginBottom:'20px',marginX:'30px'}}/>
        {/* Covered Retention Metrics */}
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main }}>
          Application Software
        </Typography>

        <Typography variant="body1" gutterBottom>
          Application Software is comprized of all horizontal end-user facing applications.  This includes front-office and back-office applicatiosn as well as productivity and colloboration apps.  For more details on application software components look at the description below.
        </Typography>
        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        CRM, Marketing & Support
        </Typography>
        <Typography variant="body1" gutterBottom>
        These solutions cater to businesses aiming to optimize customer relationships, marketing campaigns, and support systems. The markets served include retail, financial services, SaaS companies, and e-commerce platforms. Customer Relationship Management (CRM) systems help organizations manage their customer interactions and data efficiently, from lead generation to after-sales service. Marketing tools focus on automation, analytics, and personalized customer outreach, while support platforms streamline helpdesk operations and customer communication. These tools often integrate with AI-powered features for predictive analytics, sentiment analysis, and omnichannel support.  Constituents include Salesforce, HubSpot, Freshworks, Braze, and Zendesk this space.</Typography>
        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        HR, Finance, Legal & SCM
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category includes platforms supporting the operational backbone of organizations, including human resources, financial management, legal compliance, and supply chain operations. Markets served range from enterprise organizations to small and medium businesses (SMBs). HR systems handle employee lifecycle management, payroll, and talent acquisition, while finance tools address budgeting, forecasting, and expense management. Legal platforms focus on compliance tracking, contract management, and litigation support, while supply chain tools optimize procurement, inventory management, and logistics. These solutions are indispensable for organizations aiming to improve operational efficiency and ensure regulatory adherence. Constituents include Workday, ADP, Coupa, LinkedIn, and Intuit are leaders in this category.
        </Typography>
        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Productivity & Content Creation
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category focuses on enhancing team collaboration, streamlining workflows, and supporting creative endeavors. The markets served include corporate enterprises, creative industries, and education. Productivity tools enable efficient project management, document sharing, and real-time collaboration, fostering teamwork and innovation. Content creation platforms cater to the needs of designers, writers, and marketers, offering tools for graphic design, video editing, and publishing. These platforms often integrate with cloud services for seamless collaboration and data synchronization. Representative companies include Adobe, Atlassian, Figma, Zoom, and Canva.
        </Typography>
        <Divider sx={{margin:'20px'}}/>

        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main }}>
          Infrastructure Software
        </Typography>

        <Typography variant="body1" gutterBottom>
          Infrastructure Software is comprized all infrastructure software necessary to build, deliver and maintain the software ecosystem.  This databases, devops, security, and infrastructure systems.  For more details on infrastructure software components look at the description below.
        </Typography>
        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Database & Data Platforms
        </Typography>
        <Typography variant="body1" gutterBottom>
        Companies in this category provide foundational tools and systems for storing, processing, and analyzing data at scale. These platforms cater to various markets, including enterprise IT, financial services, healthcare, and retail, enabling organizations to make data-driven decisions. Solutions range from traditional relational databases for structured data to NoSQL databases for unstructured and semi-structured data. Data warehouses and lakes are also central to this category, offering capabilities for large-scale analytical processing and integration with advanced AI/ML workflows. Additionally, companies in this space often provide data integration, transformation, and orchestration tools that allow businesses to unify disparate datasets, improve data quality, and ensure compliance with data governance regulations. Representative companies include MongoDB, Snowflake, Databricks, Couchbase, and Redis.
        </Typography>
        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        DevTools & IT Ops
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category encompasses tools designed to support developers, IT operations teams, and the integration of both through DevOps practices. The markets served include software development, IT services, cloud infrastructure, and enterprise IT management. These tools streamline the software development lifecycle, from coding and version control to deployment and monitoring. They also provide real-time visibility into system performance, enabling rapid identification and resolution of issues. Platforms in this category often integrate with observability and monitoring tools to track application and infrastructure health, ensuring high availability and reliability. IT service management and automation solutions also fall under this category, addressing help desk operations, incident response, and asset management. Companies like GitLab, HashiCorp, Dynatrace, Splunk, and New Relic lead in this space.</Typography>        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Security, Compliance & UEM
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category focuses on protecting digital environments, managing enterprise endpoints, and ensuring adherence to regulatory standards. It spans markets such as cybersecurity, enterprise IT, and government sectors. Solutions in this space provide comprehensive tools for threat detection, risk management, and vulnerability mitigation. Identity and access management (IAM) platforms safeguard user authentication and authorization processes, while unified endpoint management (UEM) ensures the secure deployment and monitoring of devices across an organization. Companies also address compliance requirements with features for audit trails, encryption, and adherence to industry standards like GDPR and HIPAA. Representative companies include CrowdStrike, Okta, ZScaler, Qualys, and Palo Alto.</Typography>        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        AI Tools, IaaS, APIs & Low Code
        </Typography>
        <Typography variant="body1" gutterBottom>
        Companies in this category provide cutting-edge solutions for leveraging artificial intelligence, cloud infrastructure, API-driven platforms, and low-code/no-code development. These tools serve markets such as enterprise IT, application development, and data science. AI tools empower businesses with machine learning models, natural language processing, and predictive analytics, while Infrastructure as a Service (IaaS) platforms offer scalable compute and storage resources. APIs simplify integration with third-party services, enhancing system interoperability. Low-code and no-code platforms democratize application development, enabling non-technical users to build and deploy solutions rapidly. Key players include Twilio, OpenAI, Squarespace, Airtable, and Zapier.</Typography>
        <Divider sx={{margin:'30px'}}/>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Networking and CDN
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category includes platforms designed to optimize network performance, secure data transfer, and deliver content efficiently across global audiences. Markets served include internet infrastructure, e-commerce, and media. Content Delivery Networks (CDNs) accelerate the delivery of web content by caching assets close to end users, reducing latency and enhancing user experience. Networking tools ensure reliable data flow, monitor performance, and provide secure access to corporate resources. These platforms are critical for organizations managing large-scale online operations and require seamless digital performance. Representative companies include Cloudflare, Akamai, Fastly, and SolarWinds.
        </Typography>
        <Divider sx={{margin:'30px'}}/>


        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main }}>
          Vertical Software
        </Typography>

        <Typography variant="body1" gutterBottom>
        Vertical Software comprises of all Software built for a specific vertical(s).  These companies tend to be focused on a single end-market or industry rather than horizontally a persona across all companies</Typography>
        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Engineering & Industrials
        </Typography>
        <Typography variant="body1" gutterBottom>
        Companies in this category offer software solutions tailored to engineering, design, and industrial applications. Markets served include construction, manufacturing, aerospace, and automotive sectors. Tools like Computer-Aided Design (CAD) and simulation software enable engineers and designers to model, analyze, and optimize their projects. Industrial IoT platforms provide real-time monitoring and automation for factory operations, while project management tools streamline large-scale industrial workflows. These platforms are essential for driving innovation, efficiency, and safety in engineering and industrial operations. Leading companies include Autodesk, Procore, Bentley, PTC, and Ansys.
        </Typography>
        <Divider sx={{margin:'20px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Financials
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category serves financial institutions, enterprises, and investors with tools for data analytics, risk management, and operational optimization. Financial platforms offer advanced capabilities for portfolio management, loan origination, and compliance tracking. Markets served include banking, insurance, and investment firms. These platforms also support regulatory reporting, fraud detection, and customer engagement. Integration with advanced analytics and machine learning allows for more accurate forecasting and decision-making. Companies like Verisk, Guidewire, Clearwater, and FactSet lead in this category.
        </Typography>
        
        <Divider sx={{margin:'30px'}}/>
{/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Health Care
        </Typography>
        <Typography variant="body1" gutterBottom>
        Solutions in this category address the unique needs of the healthcare industry, focusing on patient care, operational efficiency, and regulatory compliance. Markets served include hospitals, clinics, research institutions, and pharmaceutical companies. Platforms offer electronic health records (EHR), clinical trial management, and telemedicine capabilities. Advanced analytics support population health management and personalized medicine initiatives, while patient engagement tools improve communication and satisfaction. These solutions play a critical role in improving healthcare outcomes while reducing costs. Companies like Cerner, Veeva, Athenahealth, and Medable are prominent in this space.
        </Typography>
        
        <Divider sx={{margin:'30px'}}/>
        {/* Database & Data Plaftorms Retention Rate */}
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Public Sector
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category includes software solutions designed for government agencies, educational institutions, and non-profit organizations. Markets served range from municipal governments to global non-profits. These platforms enhance service delivery, improve operational transparency, and ensure regulatory compliance. Education technology platforms offer tools for learning management and administrative efficiency, while government systems focus on citizen engagement, resource allocation, and public safety. Non-profit management tools streamline fundraising, donor engagement, and program tracking. Representative companies include Tyler Technologies, Blackboard, and PowerSchool.
        </Typography>
        
         <Divider sx={{margin:'30px'}}/>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Consumer
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category encompasses platforms and tools focused on enhancing consumer and retail experiences. Markets served include e-commerce, hospitality, and direct-to-consumer brands. Retail platforms offer inventory management, point-of-sale systems, and personalized customer engagement features. Consumer engagement tools focus on loyalty programs, marketing campaigns, and customer insights. Hospitality platforms streamline operations for restaurants, hotels, and travel businesses, improving efficiency and guest satisfaction. Key players include Shopify, Toast, and Lightspeed.
        </Typography>
        <Divider sx={{margin:'30px'}}/>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Other Vertical
        </Typography>
        <Typography variant="body1" gutterBottom>
        This category includes specialized software solutions targeting niche industries or unique use cases. Markets served vary widely and include legal firms, field services, and creative industries. These platforms often address specific pain points with highly tailored features, such as case management for law firms, dispatch and scheduling for field services, or interactive design for game development. Representative companies include Unity, ServiceTitan, and Clio.ware
        </Typography>
        <Divider sx={{margin:'30px'}}/>


      </Box>
      
  );
};

export default Documentation;
