import React, { useState,useEffect,useRef } from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {Paper, Box,Select,MenuItem,useMediaQuery,Typography,Link } from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import { AgGridReact } from "ag-grid-react";
import ButtonExport from './ButtonExportFinancials';
import { Link as RouterLink } from 'react-router-dom';


const SecurityAnalysisKeyMetrics = ({ticker,data, slicedMapping,alignment,alignmentScale,currency}) => {  

  const theme = useTheme();
  const gridRef = useRef(null); // Reference for the AG Grid
  const [rowData, setRowData] = useState([]);
  const [exportData, setExportData] = useState([]);

  const isTablet = useMediaQuery('(max-width:1080px)'); // Adjust breakpoint as needed
  const format = [{'field':'Revenue','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Gross Margin','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Non-Cloud Revenue','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Cloud Revenue','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Calculated Billings','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Current Bookings','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Bookings','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Current RPO','fontWeight':500,'tab':1,'calc':['% Growth','$ Change'],'format':'money'},
  {'field':'RPO','fontWeight':500,'tab':1,'calc':['% Growth','$ Change'],'format':'money'},
  {'field':'COGs','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Sales & Marketing','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'SG&A','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Research & Development','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Sales & Marketing','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'General & Administrative','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Operating Income','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Net Income','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Operating Cash Flow','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Capital Expenditures','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Shares Outstanding','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Customers','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Customers >$100k','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Customers >$1M','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Payback Period','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'value'},
  {'field':'Cloud Mix','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'percent'},
  {'field':'Free Cash Flow','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'ARR','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Document Cloud ARR','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Creative ARR','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Digital Media ARR','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Cloud ARR','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Non-Cloud ARR','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  ]
      

  const [rowCalculationMethods, setRowCalculationMethods] = useState({
    'ARR': 'YoY % Change', 
    'Creative ARR': 'YoY % Change', 
    'Document Cloud ARR': 'YoY % Change', 
    'Digital Media ARR': 'YoY % Change', 
    'Cloud ARR': 'YoY % Change', 
    'Non-Cloud ARR': 'YoY % Change', 
    'Calculated Billings': 'YoY % Change',
    'Current Bookings': 'YoY % Change', 
    'Bookings': 'YoY % Change',  
    'Current RPO': 'YoY % Change', 
    'RPO': 'YoY % Change', 
    'Non-Cloud Revenue': 'YoY % Change', 
    'Cloud Revenue': 'YoY % Change', 
    'Revenue': 'YoY % Change',
    'Gross Margin': '% of Revenue',
    'COGs': '% of Revenue',
    'Sales & Marketing': '% of Revenue',
    'SG&A': '% of Revenue',
    'Research & Development': '% of Revenue',
    'General & Administrative': '% of Revenue',
    'Operating Income': '% of Revenue',
    'Net Income': '% of Revenue',
    'Operating Cash Flow': '% of Revenue',
    'Capital Expenditures': '% of Revenue',
    'Free Cash Flow': '% of Revenue',
    'Shares Outstanding': 'YoY % Change',
    'Customers': 'YoY % Change',
    'Customers >$100k': 'YoY % Change',
    'Customers >$1M': 'YoY % Change',
    'Payback Period': 'YoY $ Change',
    'Cloud Mix': 'YoY $ Change',
  });

  const currString = currency === 'USD' ? "$" : "€"
  const getPreviousQuarter = (year) => {
    if (alignment === 'annual') {
      return `FY${parseInt(year.slice(2)) - 1}`;
    } else {
      const quarterYear = year.split('-')[1];
      const quarter = year.split('-')[0];
      let prevQuarter = quarter;
      let prevYear = quarterYear;
  
      // Update previous quarter and year based on current quarter
      switch (quarter) {
        case 'Q1':
          prevQuarter = 'Q4';
          break;
        case 'Q2':
          prevQuarter = 'Q1';
          break;
        case 'Q3':
          prevQuarter = 'Q2';
          break;
        case 'Q4':
          prevQuarter = 'Q3';
          break;
        default:
          break;
      }
  
      // If the previous quarter is Q4, decrement the year
      if (prevQuarter === 'Q4') {
        prevYear = parseInt(quarterYear) - 1;
      }
  
      return `${prevQuarter}-${prevYear}`;
    }
  };
  const getPreviousYear = (year) => {
    if (alignment === 'annual') {
      return `FY${parseInt(year.slice(2)) - 1}`;
    } else {
      const quarterYear = year.split('-')[1];
      const quarter = year.split('-')[0];
      let prevQuarter = quarter;
      let prevYear = quarterYear;
  
      // Update previous quarter and year based on current quarter
      switch (quarter) {
        case 'Q1':
          prevQuarter = 'Q1';
          break;
        case 'Q2':
          prevQuarter = 'Q2';
          break;
        case 'Q3':
          prevQuarter = 'Q3';
          break;
        case 'Q4':
          prevQuarter = 'Q4';
          break;
        default:
          break;
      }

      prevYear = parseInt(quarterYear) - 1;
  
      return `${prevQuarter}-${prevYear}`;
    }
  };
  const calculateYoYGrowth = (current, previous) => {
    if (current === undefined || previous === undefined) return '';
    if (previous === 0) return '';
    return formatPercentage((current - previous) / previous);
  };
  const calculatePercentageOfRevenue = (current, revenue) => {
    if (current === undefined || revenue === undefined) return '';
    if (revenue === 0) return ''; // Avoid division by zero
    return formatPercentage(current / revenue)
  };

  const calculateYoYValue = (current, revenue,format) => {
    if (current === undefined || revenue === undefined) return '';
    if (revenue === 0) return ''; // Avoid division by zero
    const growth = current - revenue
    if (format ==='percent'){
      if (growth >0){
        return `+${formatNumber(current - revenue,format,alignmentScale)}pts`;
      }
      else{
      return `${formatNumber(current - revenue,format,alignmentScale)}pts`;
      }
    }
    if (growth >0){
      return `+${formatNumber(current - revenue,format,alignmentScale)}`;
    }
    else{
    return `${formatNumber(current - revenue,format,alignmentScale)}`;
    }
  };
  const formatPercentage = (num) =>{
    if (num < 1){
      return `${(num* 100).toFixed(1)}%`;
    }
    else{
      return `${(num* 100).toFixed(0)}%`;
    }
    
  }
  const formatNumber = (num,format,scale,curr) => {
    
    if (format ==='value'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }

      if (num < 0) {
        if (Math.abs(num) >= 1000000000) {
          return `-${(Math.abs(num) / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
        } else if (Math.abs(num) > 1000000) {
          return `-${(Math.abs(num) / 1000000).toFixed(1)}M`} 
        else {
          return `${(num).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
        }
      } else if (num >= 1000000000) {
        return `${(num / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
      } else if (num > 1000000) {
        return `${(num / 1000000).toFixed(1)}M`;
      } else{
        return `${(num).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
      }
    }
    else if (format ==='percent'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }
      if (num < 1){
        return `${(num* 100).toFixed(1)}%`;
      }
      else{
        return `${(num* 100).toFixed(0)}%`;
      }
    }
    else if (format ==='money'&&scale==='M'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }
      if (num < 0) {

        if (Math.abs(num) >= 100000000) {
          return `-${currString}${(Math.abs(num) / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
        } else {
          return `-${currString}${(Math.abs(num) / 1000000).toFixed(1)}M`;
        }
      } 

      else if (num >= 100000000) {
        return `${currString}${(num / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
      } else {
        return `${currString}${(num / 1000000).toFixed(1)}M`;
      }}
      else if (format ==='money' &&scale==='B'){
        if (typeof num !== 'number' || isNaN(num)) {
          return "";
        }
        if (num < 0) {
  
          if (Math.abs(num) >= 10000000000) {
            return `-${currString}${(Math.abs(num) / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}B`;
          } else {
            return `-${currString}${(Math.abs(num) / 1000000000).toFixed(2)}B`;
          }
        } 
  
        else if (num >= 10000000000) {
          return `${currString}${(num / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}B`;
        } else {
          return `${currString}${(num / 1000000000).toFixed(2)}B`;
        }}
  };
  const handleChangeCalculationMethod = (e, field) => {
    const { value } = e.target;
    setRowCalculationMethods(prevState => ({
      ...prevState,
      [field]: value
    }));
  };
  
  useEffect(() => {
    const gridData = data.flatMap((row) => {
      // Financial row (value row)
      const financialRow = {
        field: row.field,
        type: 'value', // Used to identify this as the value row
        ...slicedMapping.reduce((acc, item) => {
          acc[item.period] = formatNumber(row[item.period], format.find(f => f.field === row.field)?.format, alignmentScale);
          return acc;
        }, {})
      };
  
      // Calculation row
      const calculationRow = {
        field: row.field,
        type: 'calculation', // Used to identify this as the calculation row
        calculationMethod: rowCalculationMethods[row.field] || 'YoY % Growth',
        ...slicedMapping.reduce((acc, item) => {
          const current = row[item.period];
          const method = rowCalculationMethods[row.field] || 'YoY % Growth';
          acc[item.period] = performCalculation(current, row, item, method); // Call performCalculation for each period
          return acc;
        }, {})
      };
  
      return [financialRow, calculationRow]; // Return both value and calculation rows
    });
    const exportData = data.map((row) => ({
      field: row.field,
      ...slicedMapping.reduce((acc, item) => {
        acc[item.period] = parseFloat(row[item.period]); // Ensure numeric value
        return acc;
      }, {})
    }));
    setRowData(gridData); // Set the grid data
    setExportData(exportData)
  }, [data, slicedMapping, alignmentScale, rowCalculationMethods]);

  const performCalculation = (current, row, item, method) => {
    let previousPeriod
    if (alignment === 'annual')
     previousPeriod = getPreviousYear(item.period);
    else {
      if (method === 'YoY % Change' || method === 'YoY $ Change'){
        previousPeriod = getPreviousYear(item.period);
      }
      else {
        previousPeriod = getPreviousQuarter(item.period);
      }
    }
    if (method === 'YoY % Change'||method === 'QoQ % Change') {
      return calculateYoYGrowth(current, row[previousPeriod]);
    }
    if (method === 'YoY $ Change'||method === 'QoQ $ Change') {
      return calculateYoYValue(current, row[previousPeriod], format.find(f => f.field === row.field)?.format);
    }
    if (method === '% of Revenue') {
      const revenueForPeriod = data.find(d => d.field === 'Revenue')?.[item.period];  // Get the revenue for the same period
      return calculatePercentageOfRevenue(current, revenueForPeriod);
    }
    // Add other methods like % QoQ, $ QoQ Change as needed
    return '';
  };

  const columns = [
    {
      headerName: alignment === 'annual' ? 'Fiscal Year' : 'Fiscal Quarter',
      headerComponentParams: { template: `<div class="ag-header-cell-label"> <span class="ag-header-cell-text"> <strong>${alignment === 'annual' ? 'Fiscal Year' : 'Fiscal Quarter'}</strong><br/> <span style="font-size: 0.8em;">Period Ended</span> </span> </div>`, },
      headerClass: "left-align-header",  
      field: 'field',
      pinned: 'left',
      minWidth: 180,
      sortable: false,
        filter: false,
        resizable: false,
    suppressMovable: true,
      width: 180,
      cellRenderer: (params) => {
        const field = params.data.field;
        const fieldFormat = format.find(f => f.field === field);
        const tabLevel = fieldFormat?.tab || 1;
        const displayPercentGrowth = fieldFormat && fieldFormat.calc && fieldFormat.calc.includes('% Growth');
        const displayValueGrowth = fieldFormat && fieldFormat.calc && fieldFormat.calc.includes('$ Change');
        const displayPercentRevenue = fieldFormat && fieldFormat.calc && fieldFormat.calc.includes('% of Revenue');

        // Add padding based on tabLevel
        const paddingLeft = `${tabLevel * 7}px`; // Adjust multiplier to control padding level
  
        if (params.data.type === 'calculation') {
          return (
            <Select
              value={rowCalculationMethods[field] || 'YoY % Growth'}
              onChange={(e) => handleChangeCalculationMethod(e, field)}
              fullWidth
              variant="standard"
              
              sx={{
                fontSize: '13px',
                color: theme.palette.text.secondary,
                display: 'flex',
                alignItems: 'center',
                height: '110%',
                paddingLeft: `${(tabLevel) * 7+3}px`, // Add extra padding for calculations
              }}
            >
              {displayPercentGrowth&&<MenuItem value="YoY % Change">YoY % Change</MenuItem>}
              {displayValueGrowth&&<MenuItem value="YoY $ Change">YoY Value Change</MenuItem>}
              {displayPercentGrowth&& alignment==='quarterly'&&<MenuItem value="QoQ % Change">QoQ % Change</MenuItem>}
              {displayValueGrowth&& alignment==='quarterly'&&<MenuItem value="QoQ $ Change">QoQ Value Change</MenuItem>}
              {displayPercentRevenue&&<MenuItem value="% of Revenue">% of Revenue</MenuItem>}
            </Select>
          );
        }
  
        return (
          <div style={{ paddingLeft }}>{field}</div>
        );
      },
      cellStyle: (params) => {
        const fieldFormat = format.find(f => f.field === params.data.field);
        const tabLevel = fieldFormat?.tab || 1;
        const paddingLeft = `${tabLevel * 7}px`; // Adjust the same padding level
  
        return params.data.type === 'calculation'
          ? { fontStyle: 'italic', color: theme.palette.text.secondary, paddingLeft: `${(tabLevel) * 7+3}px` } // Extra padding for calculations
          : { fontWeight: fieldFormat?.fontWeight || 400, paddingLeft }; // Normal rows use regular padding
      },
      cellClassRules: {
        'calculation-row': (params) => params.data.type === 'calculation',
      },
    },
    ...slicedMapping.map((item) => ({
      headerName: item.period, // Use "period" as the column header
        headerComponentParams: { template: `<div class="ag-header-cell-label"> <span class="ag-header-cell-text"> <strong>${item.period}</strong><br/> <span style="font-size: 0.8em;">${item.endDate}</span> </span> </div>`, },
        headerClass: "right-align-header",  
      sortable: false,
        filter: false,
        resizable: false,
    suppressMovable: true,
      field: item.period,
      minWidth: 105,
      width: 105,
      cellStyle: (params) => ({
        textAlign: 'right',
        fontStyle: params.data.type === 'calculation' ? 'italic' : 'normal',
        fontWeight: params.data.type === 'calculation' ? 400 : 400,
        color: params.data.type === 'calculation' ? theme.palette.text.secondary : 'inherit',
      }),
    })),
  ];
  const tableWidth=([columns.reduce((sum, colDef) => sum + colDef.width+1, 0) + 12]);

  return(
    

      <Box sx={{marginLeft:'2%',maxWidth: isTablet ?'100%':'80%', display: 'flex', flexDirection: 'column',flex: '1 1 auto' }}>


      <Box sx={{ 
        flex: '0 0 auto', // Fixed height for the header
            display: 'flex', 
            justifyContent:'space-between',
            paddingBottom: '6px', 
            width: tableWidth, 
            maxWidth: "98%", 
            color: theme.palette.primary.main, 
            fontSize: '20px', 
            fontWeight: '300 ' ,
            textAlign: 'left', // Ensure text is left-aligned
            paddingLeft: '0%',
        }}
        style={{ fontWeight: '300' }} // Inline style for testing
        >
            <Box>
          <Typography variant="h6" sx={{ flexGrow: 1,paddingBottom:'0px' }}>Financial Model</Typography>

          {/* Link to the documentation page */}
          <Link 
          component={RouterLink}
            to="/documentation/metrics"  // Update this URL to the correct documentation page
            underline="hover"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.primary.main,
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 400,
              marginTop:'-5px',
              marginLeft: 1,
            }}
          >
            <Typography 
              variant="body2" 
              sx={{
                fontWeight: 'light',
                fontStyle: 'italic',
                fontSize: '10px',
                
                color: theme.palette.text.secondary,
              }}
            >
              Our Metric Methodology
            </Typography>
            <ArrowForwardIcon sx={{ color: theme.palette.text.secondary,marginLeft: '2px', fontSize: '10px', verticalAlign: 'middle' }} />
          </Link>
          </Box>
            
            <ButtonExport
            data={exportData}
            fileName={`${ticker} - SoftwareIQ Model`}
            />
          </Box>

            {/* AG Grid Implementation */}
          
            <Paper elevation={2}
            className={theme.palette.mode === 'dark' ? "ag-theme-material-dark" : "ag-theme-material"}
            style={{
              flex: '1 1 auto', // Allow the Paper component to grow and fill the remaining space
              height: '100%', // Full height of the Paper component
              width: '100%', // Full width of the Paper component
              "--ag-odd-row-background-color": theme.tableHeader.highlight,
              "--ag-row-hover-color": theme.tableHeader.highlightDark,
              "--ag-material-primary-color": theme.palette.primary.main,
              "--ag-material-accent-color": theme.palette.primary.main,
              "--ag-header-cell-color": "theme.palette.text.secondary", // For text color
              "--ag-header-height": "50px", // Optional: Adjusts the header height
            }}
            sx={{
              maxWidth: "99%", // Ensure Paper fills the available space
              width: tableWidth, 
              flex: '1 1 auto', // Allow the Paper component to grow and fill the remaining space
              height: '100%', // Full height of the Paper component
              width: '100%', // Full width of the Paper component
              flexDirection:'column',
              '& .ag-header-cell': {
                backgroundColor: theme.table.header,
                color: theme.palette.text.secondary,
                fontWeight: 'light',
            },
            '& .right-align-header .ag-header-cell-label': {
              justifyContent: 'flex-end', // Right-align other headers
              textAlign: 'right',
            },
            '& .left-align-header .ag-header-cell-label': {
              justifyContent: 'flex-start', // Left-align the first header
              textAlign: 'left',
              },
            }}
          >

              <AgGridReact
              
                rowData={rowData}
                columnDefs={columns}
                pagination={false}
                suppressScrollOnNewData={true} // Prevent AG Grid from auto-scrolling on data update
                headerHeight={35}
                rowHeight={30}
                ref={gridRef}
                getRowHeight={params => {
                  // Set the height based on row type
                  return params.data.type === 'calculation' ? 20 : 25; // 40 for calculation rows, 30 for value rows
                }}
              />
          </Paper>
          </Box>




      )}
      export default SecurityAnalysisKeyMetrics;