import React, { useRef,useMemo } from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {Paper, Box,Typography,Link } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AgGridReact } from "ag-grid-react";
import ButtonExport from './ButtonExport';

import { Link as RouterLink } from 'react-router-dom';


const SecurityAnalysisKeyMetrics = ({ticker,tableWidth,columnDefs,filteredData, setSelectedTrendedMetric}) => {  

  const theme = useTheme();
  const gridRef = useRef(null); // Reference for the AG Grid
  const onRowClicked = (event) => {
    const selectedMetric = event.data.field; // Get the metric name from the clicked row
    setSelectedTrendedMetric(selectedMetric); // Update the selected metric state
  };


  // Filter the data based on the periods in annualSlicedMapping and format it for Nivo
  



  

  const defaultColDef = {
    resizable: false,
    suppressMovable: true,
  };
  const tableHeight = filteredData.length *30 + 47
  
  return(
    
      <Box sx={{ display: 'flex', justifyContent: 'center',height:'97%', width: tableWidth,maxWidth: '100%', }}>
      <Box sx={{width: tableWidth, maxWidth: '100%',height:'100%' }}>

          <Box sx={{width: '100%', height:'100%', paddingTop: '0px', paddingBottom: '0px', flex: 1, marginRight: '.5%', marginTop: '0px', paddingLeft: '.5%', paddingRight: '.5%' }}>
          <Box sx={{
          display: 'flex', 
          justifyContent: 'space-between',
          paddingBottom: '4px', 
          width: tableWidth, 
          maxWidth: "100%", 
          color: theme.palette.primary.main, 
          fontSize: '20px', 
          fontWeight: '300', 
          textAlign: 'left', 
          paddingLeft: '0.5%',
        }} style={{ fontWeight: '300' }}>
          <Box sx={{padding:'0px', gap:'0px'}}>
          <Typography variant="h6" sx={{ flexGrow: 1,paddingBottom:'0px' }}>Trended Metrics</Typography>

          {/* Link to the documentation page */}
          <Link 
          component={RouterLink}
            to="/documentation/metrics"  // Update this URL to the correct documentation page
            underline="hover"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.primary.main,
              cursor: 'pointer',
              fontSize: '10px',
              marginTop:'-5px',
              fontWeight: 400,
              marginLeft: 1,
            }}
          >
            <Typography 
              variant="body2" 
              sx={{
                fontWeight: 'light',
                fontStyle: 'italic',
                fontSize: '10px',
                color: theme.palette.text.secondary,
              }}
            >
              Our Metric Methodology
            </Typography>
            <ArrowForwardIcon sx={{ color: theme.palette.text.secondary,marginLeft: '2px', fontSize: '10px', verticalAlign: 'middle' }} />
          </Link>
          </Box>

          <ButtonExport
            gridRef={gridRef} 
            fileName={`${ticker} - SoftwareIQ Metrics`}
          />
        </Box>

            {/* AG Grid Implementation */}
          
            <Paper elevation={2}
            className={theme.palette.mode === 'dark' ? "ag-theme-material-dark" : "ag-theme-material"}
            style={{

              "--ag-odd-row-background-color": theme.tableHeader.highlight,
              "--ag-row-hover-color": theme.tableHeader.highlightDark,
              "--ag-material-primary-color": theme.palette.primary.main,
              "--ag-material-accent-color": theme.palette.primary.main,
              "--ag-header-cell-color": "theme.palette.text.secondary", // For text color
            }}
            sx={{
              paddingBottom: '0px',
              display: 'flex',
              flexDirection: 'column',
              height:tableHeight,
              maxHeight: '100%', // Ensure this is 100% of the parent container's height
              height:'100%',
              maxWidth: '100%', // Ensure this is 100% of the parent container's height
              flexGrow: 1,
              overflowY: 'auto', // Enable vertical scrolling when content overflows
              overflowX: 'auto', // Optional: Prevent horizontal scrolling
              '& .ag-header-cell': {
              backgroundColor: theme.table.header,
              color: theme.palette.text.secondary,
              fontWeight: 'light',
            },
            '& .right-align-header .ag-header-cell-label': {
              justifyContent: 'flex-end', // Right-align other headers
              textAlign: 'right',
              overflow: 'visible', // Prevent cutting off
            },
            '& .left-align-header .ag-header-cell-label': {
              justifyContent: 'flex-start', // Left-align the first header
              textAlign: 'left',
              overflow: 'visible', // Prevent cutting off

            },
            }}
          >

              <AgGridReact
                rowData={filteredData} // Your data array
                columnDefs={columnDefs} // Dynamically generated columns
                defaultColDef={defaultColDef}
                onRowClicked={onRowClicked}
                pagination={false}
                rowHeight={30}
                headerHeight={35}
                rowDragManaged={true}
                ref={gridRef}
                domLayout={"normal"}
              />
    
          </Paper>
          </Box>
          </Box>

      </Box>

      )}
      export default SecurityAnalysisKeyMetrics;