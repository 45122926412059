import React, { useState,  useRef,useEffect, useMemo } from "react";
import { Box, Paper, Link,Divider,Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import coverage from "../data/charts/compsheet2.json";
import { useTheme } from "@mui/material/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { useMediaQuery } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import ButtonExport from "../components/ButtonExport.jsx";
import SecurityIcon from '@mui/icons-material/Security';

function MarketCoverage({categoryInput, open, handleToggleCollapse}) {
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
  const textHide  = useMediaQuery('(max-width:1350px)'); // Adjust breakpoint as needed
  const theme = useTheme();
  const [rowData] = useState(coverage);
  const gridRef = useRef(null); // Reference for the AG Grid
  const selectedCategory =[23,24]
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed

  const [selectedColumns, setSelectedColumns] = useState(isMobile?[
    "Company Name",
    "EV/Sales NTM",
    "Revenue YOY%",
    "CRPO YOY%",
    "RPO YOY%",
    "Billings YOY%",
  ]:[
    "Company Name",
    "Enterprise Value",
    "EV/Sales NTM",
    "Revenue YOY%",
    "CRPO YOY%",
    "RPO YOY%",
    "Billings YOY%",
    "ARR YOY%",
    "NRR",
    "Payback Period"
  ]);
  
  const filterCategories = [
    { id: 10, label: 'CRM, Marketing & Support' },
    { id: 11, label: 'HR, Finance, Legal & SCM' },
    { id: 12, label: 'Productivity & Content Creation'},
    { id: 20, label: 'Database & Data Platforms' },
    { id: 21, label: 'DevTools & IT Ops'},
    { id: 22, label: 'AI Tools, IaaS, APIs & Low Code'},
    { id: 23, label: 'Security, Compliance & UEM'},
    { id: 24, label: 'Networking & CDN'},
    { id: 30, label: 'Financials'},
    { id: 31, label: 'Engineering & Industrials' },
    { id: 32, label: 'Health Care'},
    { id: 33, label: 'Consumer'},
    { id: 34, label: 'Public Sector'},
    { id: 35, label: 'Other Vertical'},
    { id: 4, label: 'Diversified Software'},
];


  // Function to handle category filter change

  // Filter the data based on the selected category
  const filteredData = useMemo(() => {
    if (selectedCategory.length === 0) {
      return []; // Return all data if no category is selected
    }
    
    // Get the labels corresponding to the selected category IDs
    const selectedLabels = filterCategories
      .filter(category => selectedCategory.includes(category.id))
      .map(category => category.label);
    
    return rowData.filter(row => selectedLabels.includes(row.Market));
  }, [rowData, selectedCategory, filterCategories]);

  
  const companyCellRenderer = (params) => {
    const ticker = params.data?.Ticker;
    const companyName = params.value;
    const iconSrc = theme.palette.mode === 'dark'
      ? `/logos/${ticker}/icondark.png`
      : `/logos/${ticker}/icon.png`;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img 
          src={iconSrc} 
          alt={`${companyName} logo`} 
          style={{ 
            width: 24, 
            height: 24, 
            marginRight: 6, 
            objectFit: 'contain' 
          }} 
        />
        <Link component={RouterLink} to={`/company/${ticker.toLowerCase()}/keymetrics` }>{companyName}</Link>
      </Box>
    );
  };

  const employeesCellRenderer = (params) => {
    const employees = params.value;
    return employees ? employees.toLocaleString() : '';
  };

  const valuationCellRenderer = (params) => {
    let valuation = params.value/1000000000;
    valuation = valuation < 100 ? valuation.toFixed(1) : valuation.toFixed(0);
    return valuation !== null && valuation !== undefined 
      ? `$${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}B` 
      : '';
  };
  const multipleCellRenderer = (params) => {
    let valuation = params.value;
    if (typeof valuation === 'number') {
        valuation = valuation.toFixed(1);
        return `${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}x`;
    }
    return '';
};
const paybackCellRenderer = (params) => {
  let valuation = params.value;
  if (valuation === null || valuation === undefined || isNaN(valuation)) {
    return "-";
}
  if (typeof valuation === 'number') {
      valuation = valuation.toFixed(1);
      return `${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return '';
};
  const percentageCellRenderer = (params) => {
    let value = params.value;

    if (value === null || value === undefined || isNaN(value)) {
        return "-";
    }
    let percentage
    if (value < 1){
       percentage = (value * 100).toFixed(1);
    }
    else {
       percentage = (value * 100).toFixed(0);
    }
    return `${percentage}%`;
};
const numericalComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  if ((valueA === null|| valueA === undefined) && (valueB === null|| valueB === undefined)) {
      return 0;
  }
  if (valueA === null|| valueA === undefined) {
      return isInverted ? -1 : 1;
  }
  if (valueB === null|| valueB === undefined) {
      return isInverted ? 1 : -1;
  }

  return valueA - valueB;
}

  const allColDefs = [
    { field: "Company Name",group:"Main", filter: "agTextColumnFilter", floatingFilter: false, cellRenderer: companyCellRenderer, maxWidth:200,width:160, pinned:'left', minWidth:150,},
    { field: "Category",group:"Main", filter: "agTextColumnFilter", floatingFilter: false, maxWidth: 180,width: 150, minWidth: 110 },
    { field: "Market",group:"Main", filter: "agTextColumnFilter", floatingFilter: false, maxWidth: 270, width: 250, minWidth: 150 },
    
    { field: "Reported Date",group:"Main", filter: "agDateColumnFilter",

    valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString(); 
    }, 
    filterParams: {
      // Custom comparator for date filtering
      comparator: (filterDate, cellValue) => {
          // Ensure both values are valid dates
          if (!filterDate || !cellValue) return 0; // Not comparable
          const filterDateObj = new Date(filterDate);
          const cellDateObj = new Date(cellValue);
          
          if (cellDateObj < filterDateObj) return -1; // Cell date is before filter date
          if (cellDateObj > filterDateObj) return 1;  // Cell date is after filter date
          return 0; // Dates are equal
      }
  },
     floatingFilter: false, maxWidth: 150,width:145, minWidth: 100 },
    { field: "Market Cap",group:"Main",filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 200, width:110, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Enterprise Value",group:"Main", filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 200, width:124, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Employees",group:"Main", filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: employeesCellRenderer, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Year Founded",group:"Main", filter: "agNumberColumnFilter", floatingFilter: false, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "EV/Sales NTM",group:"Main", filter: "agNumberColumnFilter", headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >EV/Sales</div>
                      <div style="font-size: smaller; color: gray;">NTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    }, floatingFilter: false, cellRenderer: multipleCellRenderer, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "EV/GM NTM",group:"Main", filter: "agNumberColumnFilter", headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >EV/GM</div>
                      <div style="font-size: smaller; color: gray;">NTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },floatingFilter: false, cellRenderer: multipleCellRenderer, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    
    { field: "Revenue TTM",group:"Metrics", filter: "agNumberColumnFilter", headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Revenue</div>
                      <div style="font-size: smaller; color: gray;">TTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    ,
    { field: "Operating Income TTM",group:"Metrics", filter: "agNumberColumnFilter", headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Operating Income</div>
                      <div style="font-size: smaller; color: gray;">TTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Free Cash Flow TTM",group:"Metrics", filter: "agNumberColumnFilter", headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >FCF</div>
                      <div style="font-size: smaller; color: gray;">TTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "cRPO",group:"Metrics", filter: "agNumberColumnFilter", headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >cRPO</div>

                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "RPO",group:"Metrics", filter: "agNumberColumnFilter", headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >RPO</div>

                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 200, width:100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    ,
 { field: "Revenue YOY%",group:"Growth", filter: "agNumberColumnFilter",
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
                <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                    <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                    <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                    <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                    <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                    <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                    <span >
                        <div >Revenue</div>
                        <div style="font-size: smaller; color: gray;">YoY%</div>
                    </span>
                    <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                </div>
            </div>`,
      },       
      floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Gross Margin YOY%",group:"Growth",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Gross Margin</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Billings YOY%",group:"Growth",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Billings</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "RPO YOY%",group:"Growth",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >RPO</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "CRPO YOY%",group:"Growth", headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >cRPO</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    }, filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 100, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Bookings YOY%",group:"Growth",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Bookings</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Current Bookings YOY%",group:"Growth",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Current Bookings</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    
    { field: "ARR YOY%",group:"Growth",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >ARR</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Customers YOY%",group:"Growth",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Customers</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Customers >$100k YOY%",group:"Growth",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Customers >$100k</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    
    
    { field: "Payback Period",group:"KPIs",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Payback Period</div>
                      <div style="font-size: smaller; color: gray;">Months</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: paybackCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "NRR",group:"KPIs",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >NRR</div>
                      <div style="font-size: smaller; color: gray;">TTM %</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    
    { field: "Cloud Mix %",group:"KPIs",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Cloud Mix</div>
                      <div style="font-size: smaller; color: gray;">%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    
    { field: "Current RPO Mix %",group:"KPIs",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >cRPO Mix</div>
                      <div style="font-size: smaller; color: gray;">%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    
    { field: "RPO Duration",group:"KPIs",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >RPO Duration</div>
                      <div style="font-size: smaller; color: gray;">Years</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: paybackCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    { field: "Rule of 40",group:"KPIs",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Rule of 40</div>
                      <div style="font-size: smaller; color: gray;">TTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90,sortingOrder: ["desc", "asc", null] },
    
    { field: "GM % of Sales",group:"Profitability",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >GM %</div>
                      <div style="font-size: smaller; color: gray;">TTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90 },
    { field: "S&M % of Sales",group:"Profitability",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >S&M %</div>
                      <div style="font-size: smaller; color: gray;">TTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90 },
    { field: "R&D % of Sales",group:"Profitability",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >R&D %</div>
                      <div style="font-size: smaller; color: gray;">TTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90 },
    { field: "G&A % of Sales",group:"Profitability",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >G&A %</div>
                      <div style="font-size: smaller; color: gray;">TTM</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90 },
    
    { field: "Share Count YOY%",group:"Profitability",  headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span >
                      <div >Share Count</div>
                      <div style="font-size: smaller; color: gray;">YoY%</div>
                  </span>
                  <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`,
    },filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: percentageCellRenderer,comparator: numericalComparator, maxWidth: 150, width: 90, minWidth: 90 },
    
    
  ];
  // Group columns by their 'group' property

  const filteredColDefs = useMemo(() => { 
    return allColDefs.filter((colDef) => selectedColumns.includes(colDef.field));
  }, [selectedColumns,theme.palette.mode]);
  const tableWidth = Math.max(
    filteredColDefs.reduce((sum, colDef) => sum + colDef.width, 0) + 10,
    500
  );
  const defaultColDef = useMemo(() => ({
    filter: true,
    floatingFilter: false,
    sortable: true,
    resizable: true,
    suppressMenuHide: false,
    suppressHeaderMenuButton: false,
  }), []);

  return (
    <Box sx={{ width:'100%',maxWidth:'100%',height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>

<Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <Box sx={{ display: 'flex', justifyContent: 'left', paddingTop: '0px', paddingBottom: '6px', marginLeft: '1%', color: theme.palette.primary.main, fontSize: '30px', fontWeight: '600' }}>
            Public Comp Sheet
          </Box>
          
          <Box sx={{paddingTop:'5px',paddingLeft:'10px',paddingRight:'10px',maxWidth:'290px' }}>
        <SecurityIcon sx={{fontSize:"45px",color:theme.palette.primary.main,paddingBottom:'4px'}}/>
          </Box>
          
          </Box>
          {textHide&&<Box sx={{display:'flex',justifyContent:'center',width:'90%',marginBottom:'6px'}}>
          <Typography sx={{width:'95%',color:theme.palette.text.secondary}}variant="body1">
            Discover and analyze public companies with the most comprehensive software performance metrics. Effortlessly sort, filter, and interact with dynamic data, or export your customized analysis to Excel for deeper insights.</Typography>
            </Box>}
          <Box sx={{display:'flex',justifyContent:'center'}}>
          <Divider sx={{width:'95%'}}>
            
          </Divider>
          </Box>


  {/* Flex container for centering the grid */}
  
  <Box sx={{ marginTop:'20px',flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box sx={{ 
      display: 'flex', 
      justifyContent:'space-between',
      paddingBottom: '6px', 
      width: tableWidth, 
      maxWidth: "98%", 
      color: theme.palette.primary.main, 
      fontSize: '24px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '0.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >
    Security, Compliance & UEM
    <ButtonExport
    fileName={"SoftwareIQ Comp Sheet"}
    gridRef={gridRef}
    />
  </Box>
  <Paper
    className={theme.palette.mode === 'dark' ? "ag-theme-material-dark" : "ag-theme-material"}
    style={{
      maxWidth: "98%", // Ensure Paper fills the available space
      width: tableWidth, // Ensure Paper fills the available space
      height: '570px',
    "--ag-odd-row-background-color": theme.tableHeader.highlight,
    "--ag-row-hover-color": theme.tableHeader.highlightDark,
    "--ag-material-primary-color": theme.palette.primary.main,
    "--ag-material-accent-color": theme.palette.primary.main,    
    "--ag-header-cell-color": "theme.palette.text.secondary", // For text color
    "--ag-header-height": "50px", // Optional: Adjusts the header height


    }}
    sx={{
      '& .ag-header-cell': {
        backgroundColor: theme.table.header,
        color: theme.palette.text.secondary,
        letterSpacing:"normal",
        wordSpacing:"normal",
        whiteSpace: "normal", // Allow text wrapping
        lineHeight: "1.2em", // Adjust line height for better readability
        textAlign: 'center', // Optional: Center text if needed
        padding: '0px 10px', // Adjust padding as needed (top/bottom left/right)

      },
      '& .ag-header-cell-text': {
        letterSpacing:"normal",
        textTransform:"none",
        wordSpacing:"normal",
        whiteSpace: "normal", // Allow text wrapping
      },
    }}
  >
    <AgGridReact
      ref={gridRef}
      rowData={filteredData}
      columnDefs={filteredColDefs}
      defaultColDef={defaultColDef}
      headerHeight={50}
      rowHeight={30}
    />
  </Paper>
</Box>
</Box>
  );
}

export default MarketCoverage;