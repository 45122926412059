import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from "@nivo/line";
import { isValid } from 'date-fns';

const MyResponsiveLine = ({ data, theme }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showLegend, setShowLegend] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const dynamicThreshold = (114 * data.length) + 40;
        setShowLegend(windowWidth >= dynamicThreshold);
    }, [windowWidth, data.length]);

    const isDarkMode = theme.palette.mode === 'dark';

    const themeGraph = {
        textColor: isDarkMode ? 'white' : 'black',
        grid: {
            line: {
                stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            },
        },
        axis: {
            legend: {
                text: {
                    fill: isDarkMode ? 'white' : 'black',
                    fontWeight: 'bold',
                },
            },
            ticks: {
                text: {
                    fill: isDarkMode ? 'white' : 'black',
                },
            },
        },
        markers: {
            lineColor: isDarkMode ? 'white' : 'black',
            textColor: isDarkMode ? 'white' : 'black',
        },
        tooltip: {
            container: {
                background: isDarkMode ? '#323232' : '#FFFFFF',
                color: isDarkMode ? 'white' : 'black',
            },
        },
        legends: {
            text: {
                fill: isDarkMode ? 'white' : 'black',
            },
        },
    };

    const rebaseData = (data) => {
        if (data.length <= 1) {
            // Keep original data if only one series
            return data;
        }

        return data.map((serie) => {
            const firstValue = serie.data[0]?.y || 1; // Use the first value to normalize
            return {
                id: serie.id,
                data: serie.data.map((point) => ({
                    x: point.x,
                    y: (point.y / firstValue) * 100, // Normalize to 100
                })),
            };
        });
    };

    const formattedData = rebaseData(data);

    const customFormatDate = (date) => {
        if (!isValid(date)) return '';
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear().toString().slice(-2);
        return `${month}/${day}/${year}`;
    };

    return (
        <ResponsiveLine
            data={formattedData}
            theme={themeGraph}
            margin={{ top: 0, right: 20, bottom: 50, left: 55 }}
            colors={theme.palette.mode === 'dark' ? { scheme: 'spectral' } : { scheme: 'spectral' }}
            xScale={{
                type: 'time',
                format: '%m-%d-%Y',
                useUTC: false,
                precision: 'day',
            }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 'auto',
                stacked: false,
                reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: windowWidth < 600 ? -45 : 0,
                format: (date) => customFormatDate(date),
                legendOffset: 36,
                legendPosition: 'middle',
                ticks: windowWidth < 600 ? 5 : 10,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: data.length > 1 ? 'Index Level (Rebased to 100)' : 'Index Level',
                legendOffset: -50,
                legendPosition: 'middle',
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableGridX={false}
            enableGridY={true}
            enablePoints={false}
            enableArea={true}
            enableSlices="x"
            areaOpacity={0.05}
            areaBaselineValue={0}
            legends={showLegend ? [{
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -4,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 124,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [{
                    on: 'hover',
                    style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                    },
                }],
            }] : []}
        />
    );
};

export default MyResponsiveLine;
