import React from 'react';
import { ToggleButtonGroup,Tooltip,ToggleButton, Box, Typography, IconButton } from '@mui/material';

import { useTheme } from '@mui/material/styles';

const ButtonTimeFrame = ({ alignment, handleChange }) => {
  const theme = useTheme();



  return (
    <Box
                size="small"
                sx={{
                  
                  height: '30px',
                  width: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: '106px',
                  marginTop: '2px',
                  maxWidth: '100%',
                  flex: 1,
                }}
              >
          <Box sx={{ height:'30px',backgroundColor:theme.tableHeader.highlight,width:'124px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                border: theme.palette.mode==='light'?`1px solid ${theme.palette.grey[400]}`:`1px solid ${theme.palette.grey[700]}`, borderRadius: '4px' }}>
            <Box sx={{ maxWidth: '50px',  textAlign: 'center',  paddingBottom: '0px' }}><Typography variant="body2" sx={{paddingRight:'2px',paddingLeft:'6px', color:theme.palette.text.secondary,fontSize: '9px' }}> Format Values</Typography></Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1 }}>
                <Box sx={{ borderLeft: `0px solid ${theme.palette.divider}`, marginLeft: '0px',height:'30px', marginRight: '0px' }}>
                <ToggleButtonGroup
                  sx={{
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.tableHeader.highlight,
                    height: '100%',
                    paddingLeft: '0px',
                    '& .MuiToggleButton-root': {
                      borderColor: theme.palette.mode==='light'?theme.palette.grey[400]:theme.palette.grey[700], // Default border color
                      borderRadius: '0px', // Remove rounded corners
                      '&:not(:last-child)': {
                        borderRight: `1px solid ${theme.palette.divider}`, // Adjust for visible borders between buttons
                      },
                      '&.Mui-selected': {
                        borderColor: theme.toggleButton.selectedBorderColor, // Border color for selected button
                        backgroundColor: theme.toggleButton.selectedBackgroundColor, // Optional: Change background color
                        color: theme.toggleButton.selectedTextColor, // Optional: Change text color
                      },
                    },
                  }}
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  size="small"
                  aria-label="Time Period Toggle"
                >
                  <ToggleButton style={{ textTransform: 'none' }} value="M" sx={{paddingBottom:'7px',minWidth:'26px'}}>
                    <Tooltip title="Format Values in Millions">
                      <IconButton color={alignment === 'M' ? 'primary' : theme.palette.text.secondary}  sx={{ color: alignment === 'M' ? 'primary' : theme.palette.text.secondary, py: 0, px: 0 }}>
                        <Box>
                          <Typography variant="body2" align="center" sx={{ display:'flex',justifyContent:'center',fontWeight: 'bold', fontSize: '13px' }}>$M</Typography>
                        </Box>
                      </IconButton>
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton style={{ textTransform: 'none' }} value="B" sx={{paddingBottom:'7px',minWidth:'26px'}}>
                    <Tooltip title="Format Values in Billions">
                      <IconButton color={alignment === 'B' ? 'primary' : theme.palette.text.secondary}  sx={{ color: alignment === 'B' ? 'primary' : theme.palette.text.secondary,py: 0, px: 0 }}>
                        <Box>
                          <Typography variant="body2" align="center" sx={{ display:'flex',justifyContent:'center',fontWeight: 'bold', fontSize: '13px' }}>$B</Typography>
                        </Box>
                      </IconButton>
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              </Box>
            </Box>
            </Box>
  );
};

export default ButtonTimeFrame;